import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MinFilesValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'minfilesvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">\
                            Erreur pour le champs %(label)s<br />\
                            Minimum %(minFiles)s fichier%(plural_minFiles)s obligatoire%(plural_minFiles)s<br />\
							Vous n\'avez que %(totalFiles)s fichier%(plural_totalFiles)s chargé%(plural_totalFiles)s\</p>',
            success	:	'',
        };
    }

    getMinFiles(){
        return  this.getFx('validators').get('minFiles');
    }
    getTotalFiles(){
        let input       =   this.getFx('validators').getInput();
        let $filesFx    =   input.data($.getInjector('files')+'Motor');

        if($.isDefined($filesFx)){
            let keyFilesFx  =   input.data($.getInjector('files'));
            $filesFx.init(keyFilesFx);
            return $filesFx.getTotalSuccessFiles();
        }
    }

    getPluralMinFiles(){
        return  this.getMinFiles()      >   1   ?   's' :   '';
    }
    getPluralTotalFiles(){
        return  this.getTotalFiles()    >   1   ?   's'  :   '';
    }

    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	            :	$.escapeHtml(this.getLabel()),
                minFiles            :   this.getMinFiles(),
                totalFiles          :   this.getTotalFiles(),
                plural_totalFiles   :   this.getPluralTotalFiles(),
                plural_minFiles     :   this.getPluralMinFiles(),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    validate(){
        this.set('state', true);
        if(this.isSender()){
            if(this.getTotalFiles() < this.getMinFiles()){
                this.set('state', false);
            }
        }

        return this;
    }
}

export { MinFilesValidatorFx }