import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MaxSizeValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'maxsizevalidator';
        this.MESSAGES   =   {
            error           :   '<p class="b-validator-error">\
            						Erreur pour le champs %(label)s<br />\
							        Le poids du fichier %(fileName)s est de %(fileSizeMb)s Mb (%(fileSizeKo)s Ko)<br />\
							        Le poids maximum du fichier ne peut pas dépasser %(maxSizeMb)s Mb (%(maxSize)s Ko)\</p>',

            success	        :	'',
        };
    }

    getFile(){
        return this.getFx('validators').get('file');
    }
    getFileName(){
        return this.getFile().name;
    }
    getFileSize(){
        return this.getFile().size;
    }
    getFileSizeO(){
        return this.getFileSize();
    }
    getFileSizeKo(){
        return	(this.getFileSize() / 1000).toFixed(2);
    }
    getFileSizeMb(){
        return	(this.getFileSizeKo() / 1000).toFixed(2);
    }

    getMaxSize(){
        return  this.getFx('validators').get('maxSize');
    }
    getMaxSizeMb(){
        return	(this.getMaxSize() / 1000).toFixed(2)	||	0;
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                    label	            :	$.escapeHtml(this.getLabel()),
                    fileName            :   $.escapeHtml(this.getFileName()),
                    fileSizeMb          :   this.getFileSizeMb(),
                    fileSizeKo          :   this.getFileSizeKo(),
                    maxSizeMb           :   this.getMaxSizeMb(),
                    maxSize             :   this.getMaxSize(),
                }));
            return this.get('error');
        }

        return null;
    }

    validate(){
        this.set('state', true);

        if(this.getFileSizeKo() > this.getMaxSize()){
            this.set('state', false);
        }

        return this;
    }
}

export { MaxSizeValidatorFx }