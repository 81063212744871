import { AbstractValidatorFx } from "./AbstractValidatorFx";
import { AjaxFx } from "./../AjaxFx";

class UniqueValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);
        this.timer      =   null;
        this.REQUEST    =   null;
        this.CLASSNAME  =   'uniquevalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur du champs n\'est pas valide</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }


    validate(){
        this.set('state', true);

        if(this.REQUEST !== null){
            this    .REQUEST
                    .abort()
                    .send();
        }
        else{
            this.REQUEST    =   new AjaxFx();
            
            this    .REQUEST
                    .init(this.getInput())
                    .set('fail', function(){})
                    .set('timeout', 2000)
                    .set('done', function(){})
                    .send();
        }
        

        return this;
    }
}

export { UniqueValidatorFx }