import { AbstractService } from "./AbstractService";

class FeatureService extends AbstractService{
    constructor(state = 0){
        super(state);
        this.setId('feature-service');
    }

    on(){
        $.trigger('b-rgpd-feature-service.on');
        this.state  =   1;
    }
    off(){
        $.trigger('b-rgpd-feature-service.off');
        this.state  =   0;
    }
}

 export {FeatureService}