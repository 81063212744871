import { AbstractValidatorFx } from "./AbstractValidatorFx";

class CheckboxValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'checkboxvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La checkbox doit être "sélectionné, approuvé"</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.trim($.escapeHtml(this.getLabel())),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    validate(){
        if($.isRealNotNull(this.getValue()) && !this.getInput().is(':checked')){
            this.set('state', false);
        }

        return this;
    }
}

export { CheckboxValidatorFx }