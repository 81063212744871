import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MaxFilesValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'maxfilesvalidator';
        this.MESSAGES   =   {
            error_sender	:	'<p class="b-validator-error">\
                                    Erreur pour le champs %(label)s<br />\
                                    Maximum %(maxFiles)s fichier%(plural_maxFiles)s<br />\
							        Vous avez déjà %(totalFiles)s fichier%(plural_totalFiles)s chargé%(plural_totalFiles)s\</p>',

            error           :   '<p class="b-validator-error">\
                                    Erreur pour le champs %(label)s<br />\
                                    Le fichier %(fileName)s n\'a pas été chargé<br />\
								    Maximum %(maxFiles)s fichier%(plural_maxFiles)s<br />\
							        Vous avez déjà %(totalFiles)s fichier%(plural_totalFiles)s chargé%(plural_totalFiles)s\</p>',

            success	        :	'',
        };
    }
    getMaxFiles(){
        return  this.getFx('validators').get('maxFiles');
    }
    getTotalFiles(){
        let input       =   this.getFx('validators').getInput();
        let $filesFx    =   input.data($.getInjector('files')+'Motor');

        if($.isDefined($filesFx)){
            let keyFilesFx  =   input.data($.getInjector('files'));
            $filesFx.init(keyFilesFx);
            return $filesFx.getTotalSuccessFiles();
        }
    }

    getPluralMaxFiles(){
        return  this.getMaxFiles()      >   1   ?   's' :   '';
    }
    getPluralTotalFiles(){
        return  this.getTotalFiles()    >   1   ?   's'  :   '';
    }

    getFile(){
        return this.getFx('validators').get('file');
    }
    getFileName(){
        return this.getFile().name;
    }


    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            if(this.isSender()){
                this.setError(sprintf(this.MESSAGES.error_sender, {
                    label	            :	$.escapeHtml(this.getLabel()),
                    maxFiles            :   this.getMaxFiles(),
                    totalFiles          :   this.getTotalFiles(),
                    plural_totalFiles   :   this.getPluralTotalFiles(),
                    plural_maxFiles     :   this.getPluralMaxFiles(),
                }));
            }
            else{
                this.setError(sprintf(this.MESSAGES.error, {
                    label	            :	$.escapeHtml(this.getLabel()),
                    fileName            :   $.escapeHtml(this.getFileName()),
                    maxFiles            :   this.getMaxFiles(),
                    totalFiles          :   this.getTotalFiles(),
                    plural_totalFiles   :   this.getPluralTotalFiles(),
                    plural_maxFiles     :   this.getPluralMaxFiles(),
                }));
            }


            return this.get('error');
        }
        else {
            return null;
        }
    }



    validate(){
        this.set('state', true);

        if(this.isSender()) {
            if(this.getTotalFiles() >   this.getMaxFiles()){
                this.set('state', false);
            }
        }
        else{
            if(this.getTotalFiles() >= this.getMaxFiles()){
                this.set('state', false);
            }
        }

        return this;
    }
}

export { MaxFilesValidatorFx }