import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MinValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'minvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur entrée est trop basse<br />Minimum %(min)s</p>',
            success	:	'',
        };
    }
    getLength(){
        return  $.isRealNull(this.getValue())   ?   0   :   this.getValue().length;
    }
    getMin(){
        return  this.getFx('validators').get('min');
    }
    getPlural(){
        return  this.getMin()    >   1   ?   's'  :   '';
    }
    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
                min     :   this.getMin(),
                plural  :   this.getPlural(),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    filter(){
        let value   =   this.getValue() || '';
        if(value < this.getMin()){
            value   =   this.getMin();
        }

        this.getInput().val(value);
        return this;
    }


    validate(){
        let sender  =   this.isSender();
        let minimum =   this.getMin();
        let value   =   this.getValue();
        let control;

        if(this.getFx('validators').get('numbers')){
            control =   isNaN(value)    ?   0   :   value;
        }
        else{
            control =   isNaN(value)    ?   this.getLength()   :   value;
        }



        if(sender && (minimum > control)){
            this.set('state', false);
        }
        return this;
    }
}

export { MinValidatorFx }