import { AbstractService } from "./AbstractService";

class GoogleAdsenseService extends AbstractService{
    constructor(state = 0){
        super(state);
        this.setId('google-adsense-service');
    }
    
    getCa(){
        return  'ca-pub-8260359505946143';
    }

    getAds(){
        return  {
            '#google-adsense-service-v1'    :   6438719020,
            '#google-adsense-service-v2'    :   4785891859,
            '#google-adsense-service-v3'    :   1220205459,
            '#google-adsense-service-v4'    :   5580932920,
            '#google-adsense-service-v5'    :   5861293046,
            '#google-adsense-service-v6'    :   5550796690,
            '#google-adsense-service-v7'    :   6794282529,
        };
    }

    getAdsAmp(){
        return  {
            '#google-adsense-service-v1' :  '<amp-ad width="100vw" height=320\
                                                    type="adsense"\
                                                    data-ad-client="'+this.getCa()+'"\
                                                    data-ad-slot="6438719020"\
                                                    data-auto-format="rspv"\
                                                    data-full-width>\
                                                        <div overflow></div>\
                                            </amp-ad>',
        };
    }
    on(){
        return this;
        
        if(this.isOff()){
            // AMP :: <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
            let balise  =   $('<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" id="'+this.getId()+'"></script>');
            $('body').append(balise);

            let ads =   this.getAds();
            
            for(let key in ads){
                let element =   '<ins   class="adsbygoogle"\
                                        style="display:block"\
                                        data-ad-client="'+this.getCa()+'"\
                                        data-ad-slot="'+ads[key]+'"\
                                        data-ad-format="auto"\
                                        data-full-width-responsive="true"></ins>\
                                        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>';
                $(key).html(element);
            }

            //let adsbygoogle;
            //(adsbygoogle = window.adsbygoogle || []).push({});

            this.state  =   1;

            return  this;
        }
    }
    off(){
        return this;
        
        $('#'+this.getId()).remove();

        let ads =   this.getAds();
        
        for(let key in ads){
            $(key).html('<h1>YOUR PUB</h1>');
        }
        
        this.state = 0;
        return this;
    }
}

export {GoogleAdsenseService}