import { AbstractService } from "./AbstractService";

class GoogleAnalyticsService extends AbstractService {
    constructor(state = 0){
        super(state);
        this.setId('google-analytics-service');
    }

    getUA(){
        return 'UA-127740410-1';
    }
    
    on(){
        return;

        if(this.isOff()){
            if($('#'+this.getId()).length == 0){
                let balise  =   $('<script async src="https://www.googletagmanager.com/gtag/js?id='+this.getUA()+'" id="'+this.getId()+'"></script>');
                $('body').append(balise);

                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', this.getUA());
            }

            this.state  =   1;
        }
    }
    off(){
        return;
        if($('#'+this.getId()).length > 0){
            $('#'+this.getId()).remove();
            $('[src="https://www.google-analytics.com/analytics.js"]').remove();
        }
        this.state  =   0;
    }
}

export {GoogleAnalyticsService}