/*jshint esversion: 6 */
"use stict";

class ConfigurationManager{
    constructor(){
        this.zindex =   1035;
        return this;
    }

    getConfig(name){
        switch(name){
            case 'window':
                return  {
                    selector    :   '.b-win',
                    auto        :   true,
                    lightbox    :   true,
                    live        :   true,
                    loading     :   true,
                    fixed       :   true,
                    adjust      :   true,
                    resizable   :   true,
                    expand      :   true,
                    refresh     :   true,
                    footer      :   false,
                    position    :   'top',
                    positionY   :   '100',
                    spaceX      :   10,
                    spaceY      :   10,
                    events      :   'click',
                    class       :   {
                        window  :   'window',
                    },
                    tags        :   {
                        footer    :   '<footer></footer>',
                        header    :   '<header></header>',
                    },
                    attributes  :   {
                        data    :   {
                            values  :   {
                                title   :   'title',
                            },
                        }
                    },
                    plugins     :   {
                        Ajax        :   {
                            attributes  :   {
                                url :   'href',
                            }
                        },
                        Lightbox    :   {
                            class       :   'alphagray',
                            multi       :   true,
                            superpose   :   true,
                            unique      :   false,
                        },
                        Resizable   :   {
                            resize	:	function(event, ui){},
                        },
                        Draggable   :   {},
                    }
                };
                break;
            case 'form':
                return  {
                    loading     :   true,
                    plugins     :   {
                        Ajax        :   {
                            attributes  :   {
                                url :   'action',
                            }
                        },
                    }
                }
                break;
        }
    }
}

export { ConfigurationManager }