import { Rgpd } from "./Rgpd";

$(function(){
    const rgpd  =   new Rgpd();
    let request =   null;

    rgpd.initTrigger();

    /**
    *   RGPD 
    *       init b-window before control 
    */
   rgpd.control();
   $('body').on('click', '.rgpd-action', function(e){
       $.stop(e);
       let target  =   $(e.currentTarget);
       let parent  =   target.parents('.rgpd-service:first');
       let service =   parent.data('rgpdService');
       let value   =   target.data('rgpdValue');
   
       target.attr('hidden', true);

       if(value == 'accept'){
           rgpd.accept(service);
           rgpd.toSave({
               value   :   rgpd.getServices(),
               type    :   1,   // ACCEPT
               service :   service,
           }, '/rgpd/add');
           parent.children('[data-rgpd-value="refuse"]').removeAttr('hidden');
       }
       else{
           rgpd.refused(service);
           parent.children('[data-rgpd-value="accept"]').removeAttr('hidden');
       }

       if(target.hasClass('b-window-close')){
           target.parents('.b-window:first').find('.b-close').trigger('click');
       }
    });

    /**
    *   ONLINE CHECKING 
    */
    function online(state = 'start'){
        if(state == 'start' && $.isNull(request)){
            request =   setInterval(function(){
                $.ajax({
                    url     :   '/online',
                    type    :   'POST',
                    data    :   {
                        referer : document.referrer,
                    }
                });
            }, 5000);
        }
        else{
            if($.isNotNull(request)){
                clearInterval(request);
                request =   null;
            } 
        }
    };
    
    $(document).on('b-rgpd-feature-service.on b-rgpd-feature-service.off', function(e){
        e.namespace ==  'on'    ?   online('start') :   online('stop');
    });

    if($('body').find('#rgpd').length > 0){
        $('body').css('padding', 0);
    }
});