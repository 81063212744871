import { AbstractValidatorFx } from "./AbstractValidatorFx";

class ExtensionsValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'extensionsvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />L\'extension %(extension)s du fichier "%(fileName)s" n\'est pas autorisée<br />Les extensions autorisées sont %(extensions)s</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label               :   $.escapeHtml(this.getLabel()),
                extensions			:	$.escapeHtml(this.getExtensions().join(', ')),
                fileName			:	$.escapeHtml(this.getFileName()),
                extension			:	$.escapeHtml(this.getFileExtension()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    getFile(){
        return this.getFx('validators').get('file');
    }
    getFileName(){
        return this.getFile().name;
    }
    getFileType(){
        return this.getFile().type;
    }
    getFileSize(){
        return this.getFile().size;
    }
    getFileExtension(){
        return  this.getFileName().split('.').pop();
    }
    getFileNameNotExtension(){
        return  this.getFileName().substr(0, this.getFileName().lastIndexOf('.'));
    }
    getFileLastModified(){
        return this.getFile().lastModified();
    }

    setExtensions(extensions){
        if($.isDefined(extensions)){
            this.set('extensions', extensions);
        }
        return this;
    }
    getExtensions(){
        if($.isNull(this.get('extensions'))){
            this.setExtensions(this.getValidatorFx().get('extensions'));
        }

        if(!$.isPlainObject(this.get('exstensions')) && !$.isArray(this.get('extensions'))){
            let extensions	=	this.get('extensions').replace(new RegExp(" ", "gm"),"");
                extensions	=	extensions.toLowerCase();
                extensions	=	extensions.split(',');

            this.setExtensions(extensions);
        }

        return this.get('extensions');
    }
    addExtension(extension){
        if($.isDefined(extension)){
            this.getExtensions().push(extension);
        }
        return this;
    };
    removeExtension(extension){
        if($.isDefined(extension)){
            this.getExtensions().splice(this.getExtensions().indexOf(extension),1);
        }
        return this;
    };

    validate(){
        if($.isNotNull(this.getFile())) {
            if ($.inArray(this.getFileExtension().toLowerCase(), this.getExtensions()) === -1) {
                this.set('state', false);
            }
        }

        return this;
    }
}

export { ExtensionsValidatorFx }