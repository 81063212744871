import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MaxValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'maxvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur entrée est trop élevée<br />Maximum %(max)s</p>',
            success	:	'',
        };
    }
    getLength(){
        return  $.isRealNull(this.getValue())   ?   0   :   this.getValue().length;
    }
    getMax(){
        return  this.getFx('validators').get('max');
    }
    getPlural(){
        return  this.getMax()    >   1   ?   's'  :   '';
    }
    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
                max     :   this.getMax(),
                plural  :   this.getPlural(),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    filter(){
        let value   =   this.getValue() || '';
        if(value > this.getMax()){
            value   =   this.getMax();
        }

        this.getInput().val(value);
        return this;
    }

    validate(){
        let sender  =   this.isSender();
        let maximum =   this.getMax();
        let value   =   this.getValue();
        let control;

        if(this.getFx('validators').get('numbers')){
            control =   isNaN(value)    ?   0   :   value;
        }
        else{
            control =   isNaN(value)    ?   this.getLength()   :   value;
        }


        if(sender && (maximum < control)){
            this.set('state', false);
        }
        return this;
    }
}

export { MaxValidatorFx }