import { AbstractValidatorFx } from "./AbstractValidatorFx";

class AlphaValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'alphavalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />L\'entrée ne peut contenir que des lettres</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }
    setRegex(regex){
        if($.isDefined(regex)){
            this.set('regex', regex);
        }
        return this;
    }
    getRegex(){
        if($.isNull(this.get('regex'))){
            this.setRegex(/[^0-9]/gi);
        }
        return this.get('regex');
    }

    filter(){
        let value   =   this.getValue() || '';
        value       =   value.replace(this.getRegex(), '');

        this.getInput().val(value);
        return this;
    }

    validate(){
        let value   =   $.isNull(this.getValue())   ?   ''  :   this.getValue();
        if(!this.getRegex().test(value)){
            this.set('state', false);
        }
        return this;
    }
}

export { AlphaValidatorFx }