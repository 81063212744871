import "./../../ApplicationBundle/Assets/style.scss";
import "imgliquid/js/imgLiquid-min";
import {Carousel} from "bootstrap";
import "./../../Kernel/Assets/components/Fx/Functions";
import "./../../Kernel/Assets/components/b-form/component";  
import "./../../Kernel/Assets/components/b-rgpd/component";                                 //<<    FUNCTIONS, COOKIE, WINDOWFX REQUIRED
import "./../../Kernel/Assets/plugins/lazyload/component";                                  //<<    PLUGINS
import "./../../Kernel/Assets/plugins/rs/component";                                        //<<    PLUGINS
import "./../../Kernel/Assets/components/b-search/component-no-call";

$(function(){
    /**
    *   VERSIONING
    */
    let version =   '6.0.0.1';
    $('#js-version').replaceWith('<small class="text-cyan"><i class="fab fa-fw fa-js-square"></i> v'+version+'</i>');

    let carousel            =   document.querySelector('#bs-carousel');

    if(carousel !== null){
        new Carousel(carousel);
    }

    /**
    *   SEARCH COMPONENT 
    */
    $(document).on('application-pbch.render', function(e){
        let ul      =   e.parameters.ul;
        let item    =   e.parameters.item;
        
        if(ul.hasClass('list-group')){ul.addClass('list-group');}   

        let terms   =   e.parameters.search.term.split(' ');
        let li      =   null;

        if(item.label != 'empty' && item.value != 'empty'){
            for(let i in terms){
                if(terms[i] != '' && terms[i] != ' '){
                    let term      =  new RegExp(terms[i], 'ig');
                    item.keywords =  item.keywords.replace(term, '<span class="text-red bold">'+terms[i]+'</span>');
                }
            }   

            li  =   $('<li class="ui-menu-item list-group-item"><a href="'+item.canonical+'" class="d-block" title="'+item.canonical+'">'+item.keywords+'</a></li>');
        }
        
        return li.appendTo(ul);
    });
})

