/**
*   LAZY LOAD IMAGES 
*/
document.addEventListener("DOMContentLoaded", function() {
    let lazyloadImages;    
    let imageObserver;
    let lazyloadThrottleTimeout;

    if("IntersectionObserver" in window){
        lazyloadImages  =   document.querySelectorAll(".lazy");
        imageObserver   =   new IntersectionObserver(function(entries, observer){
            entries.forEach(function(entry){
                if(entry.isIntersecting) {
                    let image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove("lazy");
                    imageObserver.unobserve(image);
                    
                    if($(image).parent().hasClass('lazy-img-liquid')){
                        $(image).parent().imgLiquid();
                    }
                }
            });
        });

        lazyloadImages.forEach(function(image) {
            imageObserver.observe(image);
        });
    } 
    else{
        lazyloadImages = document.querySelectorAll(".lazy");
        
        function lazyload(){
            if(lazyloadThrottleTimeout){clearTimeout(lazyloadThrottleTimeout);}    

            lazyloadThrottleTimeout =   setTimeout(function(){
                let scrollTop   =   window.pageYOffset;
                
                lazyloadImages.forEach(function(img){
                    if(img.offsetTop < (window.innerHeight + scrollTop)){
                        img.src = img.dataset.src;
                        img.classList.remove('lazy');
                    }
                });

                if(lazyloadImages.length == 0){
                    document.removeEventListener("scroll", lazyload);
                    window.removeEventListener("resize", lazyload);
                    window.removeEventListener("orientationChange", lazyload);
                }
            }, 20);
        }

        document.addEventListener("scroll", lazyload);
        window.addEventListener("resize", lazyload);
        window.addEventListener("orientationChange", lazyload);
    }
});