"use strict";

class CookieFactory{
    constructor(){
        this.time   =   null;
        this.name   =   null;
    }

    setName(name = null){
        if(name !== null){
            this.name   =   name;
        }
        return this;
    }
    getName(){
        if(this.name === null){
            this.setName('b-cookie');
        }
        return  this.name;
    }

    setTime(time = null){
        if(time !== null){
            this.time   =   time;
        }
        return this;
    }
    getTime(){
        if(this.time === null){
            this.setTime({
                ms  :   0,
                s   :   0,
                i   :   0,
                h   :   0,
                d   :   0,
                m   :   0,
                y   :   0,
            });
        }
        return  this.time;
    }

    getExpires(){
        let moment  =   require('moment');
        let now     =   moment();

        //  DESTRUCT
        if(this.getTime() === -1){return  now.utc().format();}
        
        let ms      =   moment.duration(this.getTime()).asMilliseconds();

        now.add(ms, 'milliseconds');

        return now.utc().format();
    }

    setValue(value = null){
        if(value !== null){
            this.value  =   value;
        }
        return this;
    }
    getValue(){
        return  this.value;
    }
    getValueToString(){
        let value   =   this.getValue();
        if(typeof(value) === 'object'){
            value   =   JSON.stringify(value);
        }
        return value;
    }

    setMilliseconds(milliseconds, replace = true){
        let time    =   this.getTime();
        time.ms      =   replace ?   milliseconds   :   time.ms + milliseconds;
        this.setTime(time);
        return this;
    }
    setSeconds(seconds, replace = true){
        let time    =   this.getTime();
        time.s      =   replace ?   seconds   :   time.s + seconds;
        this.setTime(time);
        return this;
    }
    setMinutes(minutes, replace = true){
        let time    =   this.getTime();
        time.i      =   replace ?   minutes :   time.i + minutes;
        this.setTime(time);
        return this;
    }
    setHours(hours, replace = true){
        let time    =   this.getTime();
        time.h      =   replace ?   hours   :   time.h + hours;
        this.setTime(time);
        return this;
    }
    setDays(days, replace = true){
        let time    =   this.getTime();
        time.d      =   replace ?   days   :   time.d + days;
        this.setTime(time);
        return this;
    }
    setMonths(months, replace = true){
        let time    =   this.getTime();
        time.m      =   replace ?   months  :   time.m + months;
        this.setTime(time);
        return this;
    }
    setYears(years, replace = true){
        let time    =   this.getTime();
        time.y      =   replace ?   years   :   time.y + years;
        this.setTime(time);
        return this;
    }
    addMilliseconds(milliseconds, replace = false){
        return  this.setMilliseconds(milliseconds, replace);
    }
    addSeconds(seconds, replace = false){
        return  this.setSeconds(seconds, replace);
    }
    addMinutes(minutes, replace = false){
        return  this.setMinutes(minutes, replace);
    }
    addHours(hours, replace = false){
        return  this.setHours(hours, replace);
    }
    addDays(days, replace = false){
        return  this.setDays(days, replace);
    }
    addMonths(months, replace = false){
        return  this.setMonths(months, replace);
    }
    addYears(years, replace = false){
        return  this.setYears(years, replace);
    }

    create(){
        let cookie      =   this.getName() + "=" + this.getValueToString() + "; " + this.getExpires();

        //document.cookie =   cookie;
        
        $.setCookie(this.getName(), this.getValueToString(), 365);
        return  this;
    }
    destruct(){
        document.cookie = this.getName() + "=" + "" + "; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }
}

export {CookieFactory};