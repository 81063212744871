import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MinLengthValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'minlengthvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur entrée est trop courte<br />Minimum %(minLength)s caractère%(plural)s</p>',
            success	:	'',
        };
    }
    getLength(){
        return  $.isRealNull(this.getValue())   ?   0   :   this.getValue().length;
    }
    getMinLength(){
        return  this.getFx('validators').get('minLength');
    }
    getPlural(){
        return  this.getMinLength()    >   1   ?   's'  :   '';
    }
    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	    :	$.escapeHtml(this.getLabel()),
                minLength   :   this.getMinLength(),
                plural      :   this.getPlural(),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    validate(){
        let minimum =   this.getMinLength();
        let length  =   this.getLength();


        if($.isNotNull(this.getValue()) && minimum > length && this.isSender()){
            this.set('state', false);
        }
        else if(minimum > length && !this.isSender()){
            this.set('state', false);
        }

        return this;
    }
}

export { MinLengthValidatorFx }