class AbstractService{
    constructor(state = 0){
        this.state  =   state;
    }

    setId(id){
        this.id =   id;
        return this;
    }
    getId(){
        return  this.id;
    }


    isOn(){
        return  this.state  ==  1   ?   1   :   0;
    }
    isOff(){
        return  !this.isOn();
    }
}
export {AbstractService}