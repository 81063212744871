import { ConfigurationManager } from "./../../Managers/ConfigurationManager";
import { AjaxFx }               from "./../../Fx/AjaxFx";
import { Popover }              from "bootstrap";

$(function(){
    const   cm  =   new ConfigurationManager();

    $('form.b-form').FormsFx(cm.getConfig('form'));

    $(document).on($.getKeyEvents('window')+'before.showWindow', function(obj){
        let $this	=	obj[$.getInjecteur('window')];
    
        if($this.getDom('window').find('form.b-form').length > 0){
            $this.getDom('window').find('form.b-form').FormsFx({
                plugins     :   {
                    Ajax        :   {
                        attributes  :   {
                            url :   'action',
                        }
                    },
                }
            });
        }
    });
    $(document).on($.getKeyEvents('forms')+'send', function(evt){
        let $this   =   evt[$.getInjecteur('forms')];
    
        let data    =   $this.prepareData();
        let xhr		=	$this.getXhr();
    
        $this.pause();
    
        if($.isNull($this.getPlugin('Ajax')) || $.isFalse($this.get('ajax'))){
            $this.getSender().off().trigger('click');
        }
        else{
            let $ajax   =   $this.getFx('Ajax') || new AjaxFx($this.getPlugin('Ajax'));
            $ajax.init($this.getForm());
            
            if($this.get('loading') && $.isNotTrue($this.get('silent'))){
                $this   .getLoadingFiles()
                        .css({
                            width   :   '400px',
                            margin  :   'auto',
                            padding :   '15px',
                        });
    
                let $window =   $.alert($this.getLoadingFiles(), '<i class="fas fa-spin fa-fw fa-spinner"></i> Loading...');
                $window.inject($this.getLoadingFiles(), true);
            }
    
            $ajax.setSelector($this.getSelector())
                 .set('data', data)
                 .set('auto', $this.get('auto'))
                 .set('fail', $this.failAjax)
                 .set('always', $this.alwaysAjax)
                 .set('done', $this.doneAjax)
                 .set('processData', false)
                 .set('cache', false)
                 .set('contentType', false)
                 .set('beforeSend', $this.beforeSendAjax)
                 .set('context', $this)
                 .set('method', $this.getForm().attr('method'))
                 .set('xhr', xhr);
    
            $this.resetLoadingFiles();
            $ajax.send();
    
            $this.setFx('Ajax', $ajax);
        }    
    });
    $(document).on($.getKeyEvents('forms')+'after.doneAjax', function(evt){
        let $this       =   evt[$.getInjecteur('forms')];   
        let domWindow   =   $this.getForm().parents('.b-window:not(.window-static)');
        let id          =   $this.get('data').id    || null;
   
        if($this.get('data').dispatcher){
            $.trigger('b.dispatcher', $this.get('data'));
        }

        if(domWindow.length > 0){
            let $window =   domWindow.data($.getInjector('window')+'Motor');
            $window.init(domWindow.data($.getInjector('window')));
    
            if($.isNotNull(id)){
                $window.getDom('window').attr('id', id.window);
            }

            /**
            *   POPOVER 
            */
            let popoverTriggerList  =   [].slice.call($window.getDom('body').get(0).querySelectorAll('[data-bs-toggle="popover"]'))
            popoverTriggerList.map(function (target){  
                return new Popover(target, {
                    trigger: 'hover',
                    html: true,
                });
            });
    
            /**
            *   RETURN ERROR STATE 
            */
            if($.isDefined($this.get('data').state)){
                let state       =   $this.get('data').state;
                
                if($.isFalse(state)){
                    if($.isNotNull($this.get('data').html)){
                        let div  =  $('<div class="p-5"></div>');
                        div.html($this.get('data').html);
                        $.alert(div, 'Error');
                    }
                    else{
                        let messages  = $.isDefined($this.get('data').messages) ?   $this.get('data').messages   :   ['Unknown Error'];  
                    
                        let message   = '<div class="p-5">\
                                            <p class="text-center"><i class="fas fa-exclamation fa-2x text-red"></i></p>'
                                            +messages.join('')+'\
                                            <p class="text-center mt-3"><button class="btn b-btn alphagray b-close">Ok</button></p>\
                                        </div>';
                        $.alert(message, 'Error');
                    }
                }
            }
        }
        else{    
            /**
            *   RETURN ERROR STATE 
            */
             if($.isDefined($this.get('data').state)){
                let state       =   $this.get('data').state;
                
                if($.isFalse(state)){
                    if($.isNotNull($this.get('data').html)){
                        $.alert($this.get('data').html, 'Error');
                    }
                    else{
                        let messages  = $.isDefined($this.get('data').messages) ?   $this.get('data').messages   :   ['Unknown Error'];  
                    
                        let message   = '<div class="p-5">\
                                            <p class="text-center"><i class="fas fa-exclamation fa-2x text-red"></i></p>'
                                            +messages.join('')+'\
                                            <p class="text-center mt-3"><button class="btn b-btn alphagray b-close">Ok</button></p>\
                                        </div>';
                        $.alert(message, 'Error');
                    }
                }
            }
        }
    });
})