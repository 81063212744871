import { AbstractService } from "./AbstractService";

class BannerService extends AbstractService{
    constructor(state = 0){
        super(state);
        this.setId('banner-service');
    }

    on(){
        this.state  =   1;
        return this;
    }
    off(){
        this.state  =   0;   
        return this;
    }
}

 export {BannerService}