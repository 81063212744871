"use strict";

import {CookieFactory} from "./CookieFactory"

class Cookie{
    constructor(name = null){
        this.factory    =   null;
        
        if(name !== null){
            this.init(name);
        }
    }

    setFactory(factory = null){
        if(factory !== null){
            this.factory    =   factory;
        }
        return this;
    }
    getFactory(){
        if(this.factory === null){
            this.setFactory(new CookieFactory());
        }
        return this.factory;
    }

    setName(name){
        this.name   =   name;
        return this;
    }
    getName(){
        return  this.name;
    }

    getAllValues(){
        let values  =   document.cookie.split(';');
        return values;
    }
    getValue(_default = null){
        let values  =   this.getAllValues();
        let name    =   this.getName();
        name        =   name + '=';
        for(let key in values){
            let value   =   values[key];
            while(value.charAt(0) == ' '){
                value   =   value.substring(1);
            }

            if(value.indexOf(name) != -1){
                return  value.substring(name.length, value.length);
            }
        }
        return  _default;
    }

    init(name){
        this.setName(name);
        return  this;
    }
    isExist(name = null){
        let values  =   this.getAllValues();
        name        =   name === null   ?   this.getName()  :   name;
        name        =   name + '=';

        for(let key in values){
            let value   =   values[key];
            while(value.charAt(0) == ' '){
                value   =   value.substring(1);
            }

            if(value.indexOf(name) != -1){
                return  true;
            }
        }

        return  false;
    }
    destruct(){
        this.getFactory()
            .setName(this.getName())
            .destruct();

        return  this;
    }
    create(object){
        this    .getFactory()
                .setName(object.name)
                .setValue(object.value)
                .setTime(object.expires||object.time)
                .create();

        return  this;
    }
}

export {Cookie};