import { AbstractValidatorFx } from "./AbstractValidatorFx";

class NotSpecialCharsValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'notspecialcharsvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />L\'entrée ne peut pas contenir de caractères spéciaux</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    setAuthorizedChars(authorizedChars){
        if($.isDefined(authorizedChars)){
            this.set('authorizedChars', authorizedChars);
        }
        return this;
    }
    getAuthorizedChars(){
        if($.isNull(this.get('authorizedChars'))){
            this.setAuthorizedChars('[a-zA-Z0-9ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ _-]');
        }

        return this.get('authorizedChars');
    }
    setRegex(regex){
        if($.isDefined(regex)){
            this.set('regex', regex);
        }
        return this;
    }
    getRegex(){
        if($.isNull(this.get('regex'))) {
            this.set('regex', new RegExp("^" + this.getAuthorizedChars() + "{1,}$", "gi"));
        }
        return this.get('regex');
    }

    filter(){
        let value   =   this.getValue() || ''
        let filter  =   value.replace(/[^a-zA-Z0-9ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ _-]/gi, '');

        this.getInput().val(filter);
        return this;
    }

    validate(){
        let value   =   this.getValue() || '';
        if(!this.getRegex().test(value)){
            this.set('state', false);
        }
        return this;
    }
}

export { NotSpecialCharsValidatorFx }