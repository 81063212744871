import { AbstractValidatorFx } from "./AbstractValidatorFx";

class NumbersValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'numbersvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />L\'entrée ne peut contenir que des nombres</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }
    setRegex(regex){
        if($.isDefined(regex)){
            this.set('regex', regex);
        }
        return this;
    }
    getRegex(){
        if($.isNull(this.get('regex'))){
            if(this.getComma() && this.getPoint()){
                this.setRegex(/^([\d]+$)|(([\d]{1,},){1,}[\d]{1,}$)|(([\d]{1,}\.){1,}[\d]{1,}$)/);
            }
            else if(this.getComma()){
                this.setRegex(/^([\d]+$)|(([\d]{1,},){1,}[\d]{1,}$)/);
            }
            else if(this.getPoint()){
                this.setRegex(/^([\d]+$)|(([\d]{1,}\.){1,}[\d]{1,}$)/);
            }
            else{
                this.setRegex(/^([\d]+$)/);
            }
        }
        return this.get('regex');
    }

    setComma(comma){
        if($.isDefined(comma)){
            this.set('comma', comma);
        }
        return this;
    }
    getComma(){
        if($.isNull(this.get('comma'))){
            this.setComma(true);
        }
        return this.get('comma');
    }
    setPoint(point){
        if($.isDefined(point)){
            this.set('point', point);
        }
        return this;
    }
    getPoint(){
        if($.isNull(this.get('point'))){
            this.setPoint(true);
        }
        return this.get('point');
    }
    pos(position){
        let input   =   this.getInput().get(0);

        if($.isDefined(input.selectionStart)){
            input.focus();
            input.setSelectionRange(position, position);
            console.log(position);
        }

        return this;
    }
    filter(){
        let value   =   this.getValue() || '';
        let filter;
        let last    =   '';

        if(this.getComma() && this.getPoint()){
            filter      =   value.replace(/[^\d.,]{1,}/gi, '');
            last        =   filter.substr(filter.length - 1);
            
            if(last === '.' || last === ','){
                filter    =   filter+'00';
            }
        }
        else if(this.getComma()){
            filter  =   value.replace(/[^\d,]{1,}/gi, '');
            last    =   filter.substr(filter.length - 1);

            if(last === ','){
                filter    =   filter+'00';
            }
        }
        else if(this.getPoint()){
            filter  =   value.replace(/[^\d.]{1,}/gi, '');
            last    =   filter.substr(filter.length - 1);

            if(last === '.'){
                filter    =   filter+'00';
            }
        }
        else{
            filter  =   value.replace(/[^\d+]/gi, '');
            filter  =   filter.replace(/^[0]+[\d+]+/gi, '0');

            if(this.get('beginByZero') == false){
                filter  =   filter.replace(/^[0]+/gi, '');
            }
        }
        
        this.getInput().val(filter);

        if(last === '.'){
            this.pos($.strripos(filter, '.')+1);
        }
        else if(last === ','){
            this.pos($.strripos(filter, ',')+1);
        }
        
        return this;
    }
    validate(){
        let value   =   $.isNull(this.getValue())   ?   ''  :   this.getValue();

        if($.isNotNull(value) && !this.getRegex().test(value)){
            this.set('state', false);
        }
        return this;
    }
}

export { NumbersValidatorFx }