import { AbstractValidatorFx } from "./AbstractValidatorFx";

class RequiredValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'requiredvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />Le champs est requis</p>',
            success	:	''
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    validate(){
        this.set('state', true);
        if(this.getValidatorFx().isSender()) {
            if(this.getValidatorFx().isInputBFiles()){
                let $filesFx    =   this.getInput().data($.getInjector('files')+'Motor');
                let keyFilesFx  =   this.getInput().data($.getInjector('files'));

                $filesFx.init(keyFilesFx);

                let totalFiles  =   $filesFx.getTotalSuccessFiles();

                if(totalFiles === 0){
                    this.set('state', false);
                }

            } 
            else if($.isRealNull(this.getValue()) || $.isUndefined(this.getValue()) || $.isEmptyObject(this.getValue())){
                this.set('state', false);
            }
        }

        return this;
    }
}

export { RequiredValidatorFx }