import { AbstractValidatorFx } from "./AbstractValidatorFx";

class NotEmptyValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'notemptyvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur du champs ne peut pas être vide</p>',
            success	:	'',
        };
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	$.escapeHtml(this.getLabel()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    filter(){
        let filter	=	$.trim(this.getValue());
        this.getInput().val(filter);
        return this;
    }
    validate(){
        if($.isRealNull(this.getValue()) || this.getValue() === ''){
            this.set('state', false);
        }
        return this;
    }
}

export { NotEmptyValidatorFx }