import { AbstractValidatorFx } from "./AbstractValidatorFx";

class MaxLengthValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'minlengthvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur entrée est trop longue<br />Maximum %(maxLength)s caractère%(plural)s</p>',
            success	:	'',
        };
    }
    getLength(){
        return  $.isRealNull(this.getValue())   ?   0   :   this.getValue().length;
    }
    getMaxLength(){
        return  this.getFx('validators').get('maxLength');
    }
    getPlural(){
        return  this.getMaxLength()    >   1   ?   's'  :   '';
    }
    isSender(){
        return  this.getFx('validators').get('sender');
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	    :	$.escapeHtml(this.getLabel()),
                maxLength   :   this.getMaxLength(),
                plural      :   this.getPlural(),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    filter(){
        if($.isNotNull(this.getValue())) {
            let value   =   this.isSender() ?   $.trim(this.getValue()) :   this.getValue();
            let filter  =   value.substr(0, this.getMaxLength());
            this.getInput().val(filter);
        }

        return this;
    }
    validate(){
        if($.isNotNull(this.getValue()) && this.getLength() > this.getMaxLength()){
            this.set('state', false);
        }
        return this;
    }
}

export { MaxLengthValidatorFx }