"use strict";

import {Cookie} from "./../../Cookie/Cookie";
import {GoogleAnalyticsService} from "./Services/GoogleAnalyticsService";
import {GoogleAdsenseService} from "./Services/GoogleAdsenseService";
import {FeatureService} from "./Services/FeatureService";
import {NoRgpdService} from "./Services/NoRgpdService";
import {BannerService} from "./Services/BannerService";

class Rgpd{
    constructor(params = {}){
        this.cookie             =   null;
        this.cookieService      =   null;
        this.cookieParameters   =   null;
        this.fx                 =   {
            'googleAnalytics'  :   new GoogleAnalyticsService(),
            'googleAdsense'    :   new GoogleAdsenseService(),
            'feature'          :   new FeatureService(),
            'no-rgpd'          :   new NoRgpdService(),
            'banner'           :   new BannerService(),
        };


        this.services           =   null;   //  { google-analytic, google-adsens, all }
        this.id                 =   null;   //  b-rgpd
        this.policyUrl          =   null;   //  ''
        this.lang               =   null;   //  FR
        this.expires            =   null;   //  { y : 1 } // 1 year
    };
    getTrigger(){
        let trigger =   $('#rgpd-trigger-window');
        if(trigger.length == 0){
            trigger =   $('<a id="rgpd-trigger-window" class="b-win" href="/rgpd?nav=1" hidden="true"></a>');
            $('body').append(trigger);
        }
        return trigger;
    }
    initTrigger(){
        this.getTrigger();
        return this;
    }
    removeTrigger(){
        this.getTrigger().remove();
    }

    control(){
        if(this.isExist()){
            this.launchServices();

            if(!this.isAccepted('banner')){
                this.show();
            }
            else{
                this.remove();
            }
        }
        else{
            this.accept();
            this.refused('banner');
            this.show();
        }
        this.removeTrigger();
    }

    isExist(){
        return  this.getCookie().isExist();
    }
    isAccepted(service = null){
        let accepted    =   false;

        if(this.isExist()){
            let services    =   this.getServices();
            if(service === null){ service = 'all'; }
            accepted =   services[service];
        }

        return  accepted;
    }
    accept(service = null){
        let services    =   this.getServices();
        let all         =   true;
        if(service == null){
            for(let key in services){services[key]   =   true;}
        }
        else{
            services[service]   =   true;

            for( let key in services){
                if(key !== 'all'){
                    if(services[key] == false){
                        all = false;
                        break;
                    }
                }
            }

            if(all){services['all'] = true;}
        }
       
        this.getCookieService()
            .create({
                name    :   this.getId(),
                value   :   services,
                expires :   this.getExpires(),
            });

        this.launchServices();

        return  this;
    }
    delete(){
        this.refused();
        this.getCookieService().destruct();
    }
    refused(service = null){
        let services    =   this.getServices();

        if(service == null){
            for(let key in services){services[key]   =   false;}
        }
        else{
            services[service]   =   false;
            services['all']     =   false;
        }
       
        this.getCookieService()
            .create({
                name    :   this.getId(),
                value   :   services,
                expires :   this.getExpires(),
            });

        this.launchServices();

        return  this;
    }

    //  EXPIRES
    setExpires(expires = null){
        if(expires !== null){
            this.expires    =   expires;
        }
        return this;
    }
    getExpires(){
        if(this.expires === null){
            this.setExpires({ y : 1 });
        }
        return this.expires;
    }

    //  ID
    setId(id = null){
        if(id !== null){
            this.id =   id;
        }
        return this;
    }
    getId(){
        if(this.id === null){
            this.setId('b-rgpd');
        }
        return this.id;
    }

    //  LANG
    setLang(lang = null){
        if(lang !== null){
            this.lang = lang;
        }
        return this;
    }
    getLang(){
        if(this.lang === null){
            this.setLang('FR');
        }
        return this.lang;
    }

    //  POLICY URL
    setPolicyUrl(url = null){
        if(url !== null){
            this.policyUrl = url;
        }
        return this;
    }
    getPolicyUrl(){
        return  this.policyUrl;
    }

    //  SERVICES
    getServices(){
        if(this.services === null){
            this.setServices(this.generateServices());
        }
        return  this.services;
    }
    setServices(services = null){
        if(services !== null){
            this.services   =   services;
        }
        return this;
    }
    generateServices(){
        let services    =   this.getCookieService().getValue();
        let _services   =   { 'all' : false, 'googleAnalytics' : false, 'googleAdsense' : false, 'feature' : false, 'banner' : false };

        if(typeof(services) !== 'undefined' && services !== null){
            services    =   JSON.parse(services);
        }

        if($.isEmptyObject(services) || $.isPlainObject(services)){
            services    =   $.extend(true, true, _services, services);
        }

        return  services;
    }
    getService(name = null, _default = false){
        if(name !== null){
            let services    =   this.getServices();
            return  typeof(services[name]) !== 'undefined'  ?   services[name]  :   _default;
        }

        return _default;
    }
    setService(name = null){
        if(name !== null){
            let services    =   this.getServices();
            services[name]  =   false;
            this.setServices(services);
        }
        return  this;
    }

    //  COOKIE
    getCookieService(){
        if(this.cookieService === null){
            this.setCookieService(new Cookie());
        }
        return  this.cookieService;
    }
    setCookieService(cookieService = null){
        if(cookieService !== null){
            this.cookieService  =   cookieService;
        }
        return this;
    }
    getCookieParameters(){
        if(this.cookieParameters === null){
            this.setCookieParameters(this.generateCookieParameters());
        }
        return  this.cookieParameters;
    }
    setCookieParameters(cookieParameters = null){
        if(cookieParameters !== null){
            this.cookieParameters   =   cookieParameters;
        }
        return  this;
    }
    generateCookieParameters(){
        return  {
            name    :   'b-rgpd',
            time    :   { y : 1 },
            value   :   this.getServices(),
        };
    }
    getCookieParameter(parameter){
        let parameters  =   this.getCookieParameters();
        return parameters[parameter];
    }
    setCookieParameter(key, value){
        let parameters  =   this.getCookieParameters();
        parameters[key] =   value;
        return  this.setCookieParameters(parameters);
    }
    setCookie(cookie = null){
        if(cookie !== null){
            this.cookie = cookie;
        }
        return this;
    }
    getCookie(){
        if(this.cookie === null){
            let cookie  =   this.getCookieService();
            cookie.init(this.getId());

            this.setCookie(cookie);
        }

        return this.cookie;
    }

    setFx(name, service){
        this.fx[name]   =   service;
        return this;
    }
    getFx(name){
        return this.fx[name];
    }
    getAllFx(){
        return  this.fx;
    }

    getContent(){
        if($('#b-rgpd-banner').length > 0){
            return  $('#b-rgpd-banner');
        }

        return  '<div    id="b-rgpd-banner">\
                        <button class="btn b-btn btn-sm alphagray position-absolute m-1" data-b-rgpd-selector="close">\
                            <i class="fas fa-times"></i>\
                        </button>\
                        <p class="pt-3 pr-5 pl-3">\
                            Ce site utilise des cookies afin d’améliorer votre expérience et optimiser nos sites et services.\
                            Un cookie est un petit fichier de texte transféré à partir d’un serveur web vers votre navigateur web ou votre disque dur lorsque vous visitez un site web. Si votre navigateur web est configuré pour accepter les cookies, ils seront stockés dans le navigateur web ou le disque dur jusqu’à ce que la date d’expiration soit dépassée ou jusqu’à ce que vous supprimez les cookies vous-même.\
                            Vous trouverez, en cliquant <a rel="nofollow" href="/rgpd?nav=1" class="b-win" title="paramétres des cookies">ici</a>, les explications de notre utilisation des cookies sur nos sites Web et la raison pour laquelle nous les utilisons.\
                            <span class="text-red">En poursuivant votre navigation, vous acceptez le dépôt de cookies tiers et les conditions générales du site.</span>\
                        </p>\
                        <p class="text-center" data-rgpd-service="all">\
                            <a href="#" class="refuse b-window-close me-5" title="Je refuse">Je refuse</a>\
                            <button class="accept btn btn-success rgpd-action b-window-close" data-rgpd-value="accept">J\'accepte</button>\
                        </p>\
                </div>';

        return  '<div    id="b-rgpd-banner">\
                        <button class="btn b-btn btn-sm alphagray position-absolute m-1" data-b-rgpd-selector="close">\
                            <i class="fas fa-times"></i>\
                        </button>\
                        <p class="pt-3 pr-5 pl-3">\
                            Ce site utilise des cookies afin d’améliorer votre expérience et optimiser nos sites et services.\
                            Un cookie est un petit fichier de texte transféré à partir d’un serveur web vers votre navigateur web ou votre disque dur lorsque vous visitez un site web. Si votre navigateur web est configuré pour accepter les cookies, ils seront stockés dans le navigateur web ou le disque dur jusqu’à ce que la date d’expiration soit dépassée ou jusqu’à ce que vous supprimez les cookies vous-même.\
                            Vous trouverez, en cliquant <a rel="nofollow" href="/rgpd?nav=1" class="b-win" title="paramétres des cookies">ici</a>, les explications de notre utilisation des cookies sur nos sites Web et la raison pour laquelle nous les utilisons.\
                            <span class="text-red">En poursuivant votre navigation, vous acceptez le dépôt de cookies tiers et les conditions générales du site.</span>\
                        </p>\
                        <p class="text-center" data-rgpd-service="all">\
                            <a rel="nofollow" href="/rgpd?nav=1" class="b-win b-btn btn btn-secondary" title="paramétres des cookies">Je choisis</a>\
                            <button class="accept btn btn-success rgpd-action b-window-close" data-rgpd-value="accept">J\'accepte</button>\
                        </p>\
                </div>';
    }

    show(){
        $.is$(this.getContent())    ?   null    :   $('body').append(this.getContent());

        let banner  =   $('#b-rgpd-banner');
        let close   =   banner.find('[data-b-rgpd-selector="close"]');
        let accept  =   banner.find('.accept');

        banner.fadeIn(1000);

        close.data('rgpd', this);
        accept.data('rgpd', this);

        close.on('click', function(e){
            $.stop(e);
            let target  =   $(e.currentTarget);
            let $this   =   target.data('rgpd');
            $this.accept();
            banner.fadeOut(function(){
                $(this).remove();
            }); 
        });

        accept.on('click', function(e){
            //$.stop(e);
            let target  =   $(e.currentTarget);
            let $this   =   target.data('rgpd');
            $this.accept();
            banner.fadeOut(function(){
                $(this).remove();
            });
        });
    }
    remove(){
        if($('#b-rgpd-banner').length > 0){
            $('#b-rgpd-banner').remove();
        }
        return  this;
    }

    launchServices(){
        let services    =   this.getServices();
        let gAdsense    =   this.getFx('googleAdsense');
        let gAnalytics  =   this.getFx('googleAnalytics');
        let feature     =   this.getFx('feature');
        let noRgpd      =   this.getFx('no-rgpd');
        let banner      =   this.getFx('banner');
        noRgpd.on();

        for(let service in services){
            let isAccepted  =   services[service];
                       
            if(isAccepted){
                if(service === 'all' || service === 'banner'){
                    gAdsense.on();
                    gAnalytics.on();
                    feature.on();
                    banner.on();
                    return this;
                }
                else{
                    switch(service){
                        case 'googleAdsense':
                            gAdsense.on();
                            break;
                        case 'googleAnalytics':
                            gAnalytics.on();
                            break;
                        case 'feature':
                            feature.on();
                            break;
                        default:
                            break;
                    }
                }
            }
            else{
                if(service !== 'all'){
                    switch(service){
                        case 'googleAdsense':
                            gAdsense.off();
                            break;
                        case 'googleAnalytics':
                            gAnalytics.off();
                            break;
                        case 'feature':
                            feature.off();
                            break;
                        case 'banner':
                            banner.off();
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        return  this;
    }
    
    toSave(data, url){
        $.ajax({
            url     :   url,
            data    :   data,
            method  :   'POST',
        });

        return this;
    }
}

export {Rgpd};