import { AbstractValidatorFx } from "./AbstractValidatorFx";

class EmailValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);
        this.LABEL      =   'Email';
        this.CLASSNAME  =   'emailvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />L\'adresse email "%(email)s" n\'est pas une adresse email valide</p>',
            success	:	'',
        };
    }
    setRegex(regex){
        if($.isDefined(regex)){
            this.set('regex', regex);
        }
        return this;
    }
    getRegex(){
        if($.isNull(this.get('regex'))) {
            this.set('regex', new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
        }
        return this.get('regex');
    }
    getEmail(){
        return  this.getValue() || '';
    }

    getError(){
        if(!this.get('state')){
            this.setError(sprintf(this.MESSAGES.error, {
                label	    :	$.escapeHtml(this.getLabel()),
                email       :   $.escapeHtml(this.getEmail()),
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }
    validate(){
        let value   =   this.getEmail();
        if(!this.getRegex().test(value)){
            this.set('state', false);
        }
        return this;
    }
}

export { EmailValidatorFx }