"use_strict";

(function($){
    let selectText              =   function(element){
        let range;
        let selection;
              
        if(document.body.createTextRange){
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } 
        else if (window.getSelection){
            selection   =   window.getSelection();        
            range       =   document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    let slidedown               =   function(selector){
        $('body').off('click', selector);

        $('body').on('click', selector, function(e){
            $.stop(e);
            
            let link    =   $(e.currentTarget);

            if(!link.hasClass('ui-sortable-handle')){
                let parent  =   link.parent();
                let slide   =   parent.children('.slide');
                let url     =   link.attr('href');

                if(link.children('.fa-caret-left').length > 0){
                    if(parent.hasClass('border-left-a')){
                        parent.removeClass('border-left-a').addClass('border-left-b');
                    }
                    link.children('.fa-caret-left').removeClass('fa-caret-left').addClass('fa-caret-down');
                    slide.slideDown(500);
                    link.addClass('active');

                    if(slide.children('.b-loading').length  > 0){
                        $.ajax({
                            url         :   url,
                            beforeSend  :   function(jqXhr, settings){
                                jqXhr['slide']  =   slide;
                            }, 
                        })
                        .done(function(response, textStatus, jqXhr){
                            let slide   =   jqXhr['slide'];
                            slide   .children('.b-loading')
                                    .fadeOut(500, function(){
                                        $(this).remove();
                                        slide.append($(response.html));
    
                                        if(slide.parents('.b-window').length > 0){
                                            let window =    slide.parents('.b-window');
                                            let $window=    window.data($.getInjector('window')+'Motor');
    
                                            $window.init(window);
                                            $window.trigger('end');
                                        }
                                        else{
                                            /**  
                                            *   CHECKBOX 
                                            */
                                            // if(slide.find('.b-checkbox').length > 0){
                                            //     $.checkbox(slide.find('.b-checkbox'));
                                            // }
                                        }
                                    });
                        })
                        .fail(function(){})
                        .always(function(){});
                    }
                }
                else{
                    if(parent.hasClass('border-left-b')){
                        parent.removeClass('border-left-b').addClass('border-left-a');
                    }
                    link.children('.fa-caret-down').removeClass('fa-caret-down').addClass('fa-caret-left');
                    slide.slideUp(500);
                    link.removeClass('active');
                }
            }
        });
    };
    let getAction               =   function(action){
        switch(action){
            case 'page::reload':
                return function(){
                    location.reload();
                }
            break;
        }
    };
    let getInjector             =	function(name){
        let returne;

        switch(name){
            case 'lightbox':
            returne =   'b-lightbox';
            break;

            case 'timer':
            returne =   'b-timer';
            break;

            case 'ajax':
            returne =   'b-ajax';
            break;

            case 'window':
            returne =   'b-window';
            break;

            case 'forms':
            returne	=	'b-forms';
            break;

            case 'validators':
            returne	=	'b-validators';
            break;

            case 'checkbox':
            returne	=	'b-checkbox';
            break;

            case 'filemanager':
            returne	=	'b-filemanager';
            break;

            case 'files':
            returne	=	'b-files';
            break;

            case 'agenda':
            returne	=	'b-agenda';
            break;

            case 'holiday':
            returne	=	'b-holiday';
            break;

            default:
            returne =   'b-'+name.toLowerCase();
            break;
        }
        return returne;
    };
    let getKeyPrefix			=	function(name){
        let injector	=	$.getInjector(name).toLowerCase();
        return 'data-'+injector+'-';
    };
    let getPrefix               =   function(name){
        let injector   =   $.getInjector(name).toLowerCase();
        return 'data-'+injector+'-';
    };    
    let getKeyEvents			=	function(className){
        let injector   =   $.getInjector(className).toLowerCase();
        return	injector+'.';
    };
    let getKeySelector			=	function(className){
        let injector	=	$.getInjector(className).toLowerCase();
        return	'data-'+injector+'-selector';
    };
    let isString                =   function(string){
        return  typeof string   ==  'string'    ?   true    :   false;
    }
    let isNotString             =   function(string){
        return !typeof string   ==  'string'    ?   true    :   false;
    }
    let isFalse                 =   function(value){
        let returne	=	false;
        if(value === false || value === 'false'){
            returne = true;
        }
        return returne;
    };
    let isTrue			        =	function(value){
        let returne	=	false;
        if(value === true|| value === 'true'){
            returne	=	true;
        }
        return returne;
    };
    let isNotFalse		        =	function(value){
        let returne = false;
        if(value !== false && value !== 'false'){
            returne = true;
        }
        return returne;
    };
    let isNotTrue		        =	function(value){
        let returne	=	false;
        if(value !== true && value !== 'true'){
            returne = true;
        }
        return returne;
    };
    let isRealFalse		        =	function(value){
        let returne = false;
        if(value === false){
            returne = true;
        }
        return returne;
    };
    let isRealTrue		        =	function(value){
        let returne = false;
        if(value === true){
            returne = true;
        }
        return returne;
    };
    let isNotRealFalse	        =	function(value){
        let returne = false;
        if(value !== false){
            returne = true;
        }
        return returne;
    };
    let isNotRealTrue	        =	function(value){
        let returne = false;
        if(value !== true){
            returne = true;
        }
        return returne;
    };
    let isNull			        =	function(value){
        let returne = false;
        if(value === null || value === '' || typeof value == 'undefined'){
            returne = true;
        }
        return returne;
    };
    let isRealNull		        =	function(value){
        let returne = false;
        if(value === null){
            returne = true;
        }
        return returne;
    };
    let isNotNull		        =	function(value){
        let returne = false;
        if(value !== null && value !== '' && typeof value != 'undefined'){
            returne = true;
        }
        return returne;
    };
    let isRealNotNull	        =	function(value){
        let returne = false;
        if(value !== null){
            returne = true;
        }
        return returne;
    };
    let isDefined		        =	function(value){
        let returne = false;
        if(typeof value != 'undefined'){
            returne = true;
        }
        return returne;
    };
    let isUndefined		        =	function(value){
        let returne = false;
        if(typeof value == 'undefined'){
            returne = true;
        }
        return returne;
    };
    let isEmpty			        =	function(value){
        let returne = false;
        if(value === '' || value === null || typeof value == 'undefined'){
           returne = true;
        }
        return returne;
    };
    let isRealEmpty		        =	function(value){
        let returne	=	false;
        if(value === ''){
            returne = true;
        }
        return returne;
    };
    let isNotEmpty		        =	function(value){
        let returne = false;
        if(value !== '' && value !== null && typeof value != 'undefined'){
            returne = true;
        }
        return returne;
    };
    let isRealNotEmpty	        =	function(value){
        let returne	=	false;
        if(value !== ''){
            returne = true;
        }
        return returne;
    };
    let isJquery		        =	function(value){
        return value && value.hasOwnProperty && value instanceof jQuery;
    };
    let is$				        =	function(value){
        return $.isJquery(value);
    };
    let isNot$                  =   function(value){
        return !this.is$(value);
    };
    let isNotJquery             =   function(value){
        return this.isNot$(value);
    };
    let isEnter			        =	function(evt){
        let returne	=	false;
        if(evt.keyCode === 13){returne = true;}
        return returne;
    };
    let isRightClick            =   function(evt){
        return    evt.which ==	3	?	true	:	false;
    };
    let isHumanClick            =   function(evt){
        return  !$.isUndefined(evt.originalEvent);
    };
    let md5				        =	function(length = 36){
        return Math.random().toString(length).substr(2);
    };
    let key				        =	function(){
        return $.md5();
    };
    let stopDefault		        =	function(evt){
        evt.preventDefault();
    };
    let stopPropagation	        =	function(evt){
        evt.stopPropagation();
    };
    let stopped			        =	function(evt){
        $.stopPropagation(evt);
        $.stopDefault(evt);
    };
    let stop			        =	function(evt){
        $.stopped(evt);
    };
    let func			        =  	function(f, obj){
        let returne	=	obj;
        if($.isFunction(f)){returne = f(obj);}
        return returne;
    };
    let filters			        =  	function(type, data){
        switch(type){
            case 'numeric':
                data	=	data.replace(/[^\d\+]/g, "");
            break;

            case 'integer':
            case 'number::strict':
                if($.isString(data)){
                    data    =   data.replace(/[^\d\+]/g, "");         
                }
                return parseInt(data);
            break;
            
            case 'number::put::2':
                if($.isString(data)){
                    data    =   data.replace(',', '.');     
                    data    =   data.replace(/\d\,\.]{1}\d{1}/gi, '');          
                }
                
                data    =   parseFloat(data);
                data    =   data.toFixed(2);

                return  data;
            break;

            case 'number::comma::2':
                if($.isString(data)){
                    data    =   data.replace(',', '.');     
                    data    =   data.replace(/\d\,\.]{1}\d{1}/gi, '');          
                }

                data    =   data.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                data    =   parseFloat(data);
                data    =   data.toFixed(2);
                
                data    =   data.toString().replace('.', ',');  

                return data;
            break;
        }

        return data;
    };
    let scrollWidth		        =	function(){
        let $inner  =   jQuery('<div style="width: 100%; height:200px;">test</div>'),
        $outer      =   jQuery('<div style="width:200px;height:150px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),
        inner       =   $inner[0],
        outer       =   $outer[0];

        jQuery('body').append(outer);
        let width1 = inner.offsetWidth;
        $outer.css('overflow', 'scroll');
        let width2 = outer.clientWidth;
        $outer.remove();

        return (width1 - width2);
    };
    let getLoading              =   function(size = 'default'){
        let spinner, loading;

        switch(size){
          case 'small':
            spinner =   '<div class="preloader"> <div class="loader small"></div> </div>';
            loading =	  $('<div class="b-loading"></div>');
            loading.append(spinner);
            break;

          default :
            spinner =   '<div class="preloader"> <div class="loader"></div> </div>';
            loading	=	  $('<div class="b-loading"><p class="b-label">Chargement en cours...</p></div>');
            loading.append(spinner);
          break;
        }

        return loading.clone();
    };

    let getCookie		        =	function(cname){
        let name    =   cname + "=";
        let ca      =   document.cookie.split(';');
        for(let i=0; i<ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if(c.indexOf(name) != -1) return c.substring(name.length,c.length);
        }
        return "";
    };
    let setCookie		        =	function(cname, cvalue, exdays){
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires     =   "expires="+d.toUTCString();
        document.cookie =   cname + "=" + cvalue + "; " + expires;
    };

    let hide                    =   function(elements){
        if($.isDefined(elements)){
            elements.addClass('d-none');
            if(elements.is(':visible')){
                elements.css('display', 'none')
                        .removeClass('d-none');
            }
        }

        return this;
    };
    let show                    =   function(elements){
        if($.isDefined(elements)){
            elements.removeClass('d-none');
            if(!elements.is(':visible')){
                elements.css('display', 'block');
            }
        }
        return this;
    };
    let disable                 =   function(elements){
        if($.isDefined(elements)){
            elements.attr('disabled', 'disabled');
        }
        return this;
    };
    let enable                  =   function(elements){
        if($.isDefined(elements)){
            elements.removeAttr('disabled');
        }
        return this;
    };
    let float                   =   function(data){
        data.replace(/\D/, '');
        data    ===  ''  ?   data    =   0   :   data    =   parseFloat(data);
        return data;
    };
    let css                     =   function(css, elements){
        if($.isPlainObject(css)){elements.css(css);}
        return this;
    };
    let addClass                =   function(classs, elements){
        if($.isDefined(classs)){elements.addClass(classs);}
        return this;
    };
    let attr                    =   function(name, value, elements){
        if($.isDefined(name) && $.isDefined(value) && $.isDefined(elements)){
            elements.attr(name, value);
        }
        return this;
    };
    let length					=	function(array){
        let length	=	0;
        for(let i in array){
            length++;
        }
        return length;
    };
    let size					=	function(obj){
        let size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };
    let escapeHtml				=	function(text) {
        if(typeof text === 'string') {
            let map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };

            return text.replace(/[&<>"']/g, function (m) {
                return map[m];
            });
        }
        else{
            return text;
        }
    };
    let webmaster				=	function(obj){
        console.log(obj);
    };
    let elastic					=	function(elements){
        let injector	=	'b-textarea-elastic';

        elements.each(function(){
            if($(this).data(injector) !== true){
                let element	=	$(this);
                element.css({
                    'box-sizing'	:	'border-box',
                    'resize'		:	'none',
                });

                let offset = this.offsetHeight - this.clientHeight;

                let resize	=	function(_element){
                    $(_element)	.css('height', 'auto')
                                .css('height', _element.scrollHeight + offset);
                }

                element.on('keyup input', function(){resize(this);});
                element.data(injector, true);
            }
        });
    };
    let nl2br                   =   function(string){
        return  (string + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ '<br />' +'$2');
    }

    let _trim                   =   function (string){
        string = string.replace(/[\s]{2,}/g," "); // Enlève les espaces doubles, triples, etc.
        string = string.replace(/^[\s]/, ""); // Enlève les espaces au début
        string = string.replace(/[\s]$/,""); // Enlève les espaces à la fin
        return string;    
    }
    let _getPrefix				=	function(name){
        let prefixes	=	{
            action	:	getApplicationConfig().get('prefix')+'action',
            selector:	getApplicationConfig().get('prefix')+'selector',
        }

        return prefixes[name];
    }
    let _selector				=	function(selector){
        return	'['+_getPrefix('selector')+'="'+selector+'"]';
    }
    let _action					=	function(action){
        return	'['+_getPrefix('action')+'="'+action+'"]';
    }
    let getUnderline            =   function(style){
        switch(style){
            case 'color':
                return $('<div class="underline color">\
                            <div class="line">\
                                <div class="bar shadow-1"></div>\
                                <div class="bar shadow-2"></div>\
                                <div class="bar shadow-3"></div>\
                                <div class="bar shadow-4"></div>\
                                <div class="bar shadow-5"></div>\
                            </div>\
                            <div class="shadow"></div>\
                        </div>');
            break;
            case 'rainbow':
                return $('<div class="underline rainbow">\
                            <div class="line">\
                                <div class="bar shadow-1"></div>\
                                <div class="bar shadow-2"></div>\
                                <div class="bar shadow-3"></div>\
                                <div class="bar shadow-4"></div>\
                                <div class="bar shadow-5"></div>\
                                <div class="bar shadow-6"></div>\
                                <div class="bar shadow-7"></div>\
                                <div class="bar shadow-8"></div>\
                                <div class="bar shadow-9"></div>\
                                <div class="bar shadow-10"></div>\
                            </div>\
                            <div class="shadow"></div>\
                        </div>');
            break;
        }
    }
    let trigger                 =   function(type, parameters){
        let event                       =   {};
        event.type                      =   type;
        event['parameters']             =   parameters;


        let returne                     =   $.event.trigger(event);

        return returne;
    }

    let strripos                =   function(haystack, needle, offset) {
        //  discuss at: https://locutus.io/php/strripos/
        // original by: Kevin van Zonneveld (https://kvz.io)
        // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
        // bugfixed by: Brett Zamir (https://brett-zamir.me)
        //    input by: saulius
        //   example 1: strripos('Kevin van Zonneveld', 'E')
        //   returns 1: 16
        haystack = (haystack + '')
          .toLowerCase()
        needle = (needle + '')
          .toLowerCase()
        let i = -1
        if (offset) {
          i = (haystack + '')
            .slice(offset)
            .lastIndexOf(needle) // strrpos' offset indicates starting point of range till end,
          // while lastIndexOf's optional 2nd argument indicates ending point of range from the beginning
          if (i !== -1) {
            i += offset
          }
        } else {
          i = (haystack + '')
            .lastIndexOf(needle)
        }
        return i >= 0 ? i : false
    }

    let F				        =	{
        isString                :   isString,
        isNotString             :   isNotString,
        selectText              :   selectText,
        slidedown               :   slidedown,
        getAction               :   getAction,
        getInjecteur            :   getInjector,
        getInjector             :   getInjector,
        getPrefix               :   getPrefix,
        isFalse			        :	isFalse,
        isTrue			        :	isTrue,
        isNotFalse		        :	isNotFalse,
        isNotTrue		        :	isNotTrue,
        isRealFalse		        :	isRealFalse,
        isRealTrue		        :	isRealTrue,
        isNotRealFalse	        :	isNotRealFalse,
        isNotRealTrue	        :	isNotRealTrue,
        isNull			        :	isNull,
        isRealNull		        :	isRealNull,
        isNotNull		        :	isNotNull,
        isRealNotNull	        :	isRealNotNull,
        isDefined		        :	isDefined,
        isUndefined		        :	isUndefined,
        isEmpty			        :	isEmpty,
        isRealEmpty		        :	isRealEmpty,
        isNotEmpty		        :	isNotEmpty,
        isRealNotEmpty	        :	isRealNotEmpty,
        isJquery		        :	isJquery,
        is$				        :	is$,
        isNot$                  :   isNot$,
        isNotJquery             :   isNotJquery,
        isEnter			        :	isEnter,
        isRightClick            :   isRightClick,
        isHumanClick            :   isHumanClick,
        isRealClick             :   isHumanClick,
        md5				        :	md5,
        key				        :	key,
        stopDefault		        :	stopDefault,
        stopPropagation	        :	stopPropagation,
        stopped		    	    :	stopped,
        stop			        :	stop,
        func			        :	func,
        filters			        :   filters,
        scrollWidth		        :   scrollWidth,
        setCookie		        :   setCookie,
        getLoading              :   getLoading,
        getCookie		        :   getCookie,
        hide    	            :	hide,
        show                    :   show,
        disable                 :   disable,
        disabled                :   disable,
        enable                  :   enable,
        enabled                 :   enable,
        float                   :   float,
        toFloat                 :   float,
        _css                    :   css,
        _addClass               :   addClass,
        _attr                   :   attr,
        _length					:	length,
        _size					:	size,
        getKeyEvents			:	getKeyEvents,
        getKeySelector			:	getKeySelector,
        getKeyPrefix			:	getKeyPrefix,
        escapeHtml				:	escapeHtml,
        elastic					:	elastic,
        getUnderline            :   getUnderline,
        nl2br                   :   nl2br,

        _getPrefix				:	_getPrefix,
        _selector	 			:	_selector,
        _action					:	_action,
        trigger                 :   trigger,
        webmaster				:	webmaster,
        strripos                :   strripos,
        _trim                   :   _trim,
    };
    
    jQuery.extend(F);
})(jQuery);


$.fn.isOnScreen     =   function(){
    let win = $(window);

    let viewport = {
        top     :   win.scrollTop(),
        left    :   win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    let bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};

/**
*   @fileOverview device detector
*   jQuery Plugin to get Device and Browser informations
*   @author Simon Gattner <npm@0x38.de>
*   @license MIT
*   @version 1.0.0
*/

/**
*   @external "jQuery.fn"
*   @see {@link http://docs.jquery.com/Plugins/Authoring The jQuery Plugin Guide}
*/

(function($){
    'use strict';

    /**
    *   jQuery Methods to get Device and Browser informations
    *   @function external:"jQuery.fn".deviceDetector
    *   @external "jQuery.fn.deviceDetector"
    */
  
    $.fn.deviceDetector = function(options) {
        if (typeof options !== undefined) $.extend(true, config, options);
        return true;
    };
  
    /**
    *   Method to detect mobile devices.
    *   @function external:"jQuery.fn.deviceDetector".isMobile
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isMobile = function() {
        return isDeviceType(
            config.vendors.apple.ios.pattern.include,
            config.vendors.apple.ios.pattern.exclude
        ) ||
        isDeviceType(
            config.vendors.google.android.pattern.include,
            config.vendors.google.android.pattern.exclude
        ) ||
        isDeviceType(
            config.vendors.microsoft.windowsPhone.pattern.include,
            config.vendors.microsoft.windowsPhone.exclude
        ) ||
        isDeviceType(
            config.vendors.blackberry.blackberry.pattern.include,
            config.vendors.blackberry.blackberry.exclude
        ) ||
        isDeviceType(
            config.mobile.pattern.include,
            config.mobile.pattern.exclude
        );
    };
  
    /**
     * Method to detect desktop devices.
     * @function external:"jQuery.fn.deviceDetector".isDesktop
     * @return {boolean} The match status.
     */
  
    $.fn.deviceDetector.isDesktop = function() {
        return isDeviceType(
            config.vendors.apple.macos.pattern.include,
            config.vendors.apple.macos.pattern.exclude
        ) ||
        isDeviceType(
            config.vendors.lbu.unixlike.pattern.include,
            config.vendors.lbu.unixlike.pattern.exclude
        ) ||
        isDeviceType(
            config.vendors.microsoft.windows.pattern.include,
            config.vendors.microsoft.windows.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Safari.
    *   @function external:"jQuery.fn.deviceDetector".isSafari
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isSafari = function() {
        return isDeviceType(
            config.vendors.apple.safari.pattern.include,
            config.vendors.apple.safari.pattern.exclude
        );
    };
  
    /**
    *   Method to detect iPad.
    *   @function external:"jQuery.fn.deviceDetector".isIpad
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isIpad = function() {
        return isDeviceType(
            config.vendors.apple.ipad.pattern.include,
            config.vendors.apple.ipad.pattern.exclude
        );
    };
  
    /**
    *   Method to detect iPhone.
    *   @function external:"jQuery.fn.deviceDetector".isIphone
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isIphone = function() {
        return isDeviceType(
            config.vendors.apple.iphone.pattern.include,
            config.vendors.apple.iphone.pattern.exclude
        );
    };
  
    /**
    *   Method to detect iOS.
    *   @function external:"jQuery.fn.deviceDetector".isIos
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isIos = function() {
        return isDeviceType(
            config.vendors.apple.ios.pattern.include,
            config.vendors.apple.ios.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Mac OS.
    *   @function external:"jQuery.fn.deviceDetector".isMacos
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isMacos = function() {
        return isDeviceType(
            config.vendors.apple.macos.pattern.include,
            config.vendors.apple.macos.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Chrome.
    *   @function external:"jQuery.fn.deviceDetector".isChrome
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isChrome = function() {
        return isDeviceType(
            config.vendors.google.chrome.pattern.include,
            config.vendors.google.chrome.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Android.
    *   @function external:"jQuery.fn.deviceDetector".isAndroid
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isAndroid = function() {
        return isDeviceType(
            config.vendors.google.android.pattern.include,
            config.vendors.google.android.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Firefox.
    *   @function external:"jQuery.fn.deviceDetector".isFirefox
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isFirefox = function() {
        return isDeviceType(
            config.vendors.mozilla.firefox.pattern.include,
            config.vendors.mozilla.firefox.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Microsoft Internet Explorer (IE/Edge).
    *   @function external:"jQuery.fn.deviceDetector".isMsie
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isMsie = function() {
        return isDeviceType(
            config.vendors.microsoft.msie.pattern.include,
            config.vendors.microsoft.msie.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Microsoft Edge.
    *   @function external:"jQuery.fn.deviceDetector".isEdge
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isEdge = function() {
        return isDeviceType(
            config.vendors.microsoft.edge.pattern.include,
            config.vendors.microsoft.edge.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Internet Explorer.
    *   @function external:"jQuery.fn.deviceDetector".isIe
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isIe = function() {
        return isDeviceType(
            config.vendors.microsoft.ie.pattern.include,
            config.vendors.microsoft.ie.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Microsoft Internet Explorer Mobile.
    *   @function external:"jQuery.fn.deviceDetector".isIeMobile
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isIeMobile = function() {
        return isDeviceType(
            config.vendors.microsoft.ieMobile.pattern.include,
            config.vendors.microsoft.ieMobile.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Windows.
    *   @function external:"jQuery.fn.deviceDetector".isWindows
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isWindows = function() {
        return isDeviceType(
            config.vendors.microsoft.windows.pattern.include,
            config.vendors.microsoft.windows.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Windows Phone.
    *   @function external:"jQuery.fn.deviceDetector".isWindowsPhone
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isWindowsPhone = function() {
        return isDeviceType(
            config.vendors.microsoft.windowsPhone.pattern.include,
            config.vendors.microsoft.windowsPhone.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Opera.
    *   @function external:"jQuery.fn.deviceDetector".isOpera
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isOpera = function() {
        return isDeviceType(
            config.vendors.opera.opera.pattern.include,
            config.vendors.opera.opera.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Opera Mini.
    *   @function external:"jQuery.fn.deviceDetector".isOperaMini
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isOperaMini = function() {
        return isDeviceType(
            config.vendors.opera.operaMini.pattern.include,
            config.vendors.opera.operaMini.pattern.exclude
        );
    };
  
    /**
    *   Method to detect BlackBerry.
    *   @function external:"jQuery.fn.deviceDetector".isBlackberry
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isBlackberry = function() {
        return isDeviceType(
            config.vendors.blackberry.blackberry.pattern.include,
            config.vendors.blackberry.blackberry.pattern.exclude
        );
    };
  
    /**
    *   Method to detect Linux.
    *   @function external:"jQuery.fn.deviceDetector".isLinux
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isLinux = function() {
        return isDeviceType(
            config.vendors.lbu.linux.pattern.include,
            config.vendors.lbu.linux.pattern.exclude
        );
    };
  
    /**
    *   Method to detect BSD/Unix.
    *   @function external:"jQuery.fn.deviceDetector".isBsd
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isBsd = function() {
        return isDeviceType(
            config.vendors.lbu.bsd.pattern.include,
            config.vendors.lbu.bsd.pattern.exclude
        );
    };
  
    /**
    *   Method to get Browser Version.
    *   @function external:"jQuery.fn.deviceDetector".getBrowserVersion
    *   @return {string} The browser version.
    */
  
    $.fn.deviceDetector.getBrowserVersion = function() {
        return getBrowserVersion();
    };
  
    /**
    *   Method to get Browser Name.
    *   @function external:"jQuery.fn.deviceDetector".getBrowserName
    *   @return {string} The browser name.
    */
  
    $.fn.deviceDetector.getBrowserName = function() {
        return getBrowserName();
    };
  
    /**
    *   Method to get Browser Id (Short Name).
    *   @function external:"jQuery.fn.deviceDetector".getBrowserId
    *   @return {string} The browser id.
    */
  
    $.fn.deviceDetector.getBrowserId = function() {
        return getBrowserName(true);
    };
  
    /**
    *   Method to get Operating System Version.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersion
    *   @return {string} The OS version String.
    */
  
    $.fn.deviceDetector.getOsVersion = function() {
        return getOsVersion().string;
    };
  
    /**
    *   Method to get Operating System Version String.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersionString
    *   @return {string} The OS version String.
    */
  
    $.fn.deviceDetector.getOsVersionString = function() {
        return getOsVersion().string;
    };
  
    /**
    *   Method to get Operating System Version Categories.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersionCategories
    *   @return {object} The OS version Categories.
    */
  
    $.fn.deviceDetector.getOsVersionCategories = function() {
        return getOsVersion().categories;
    };
  
    /**
    *   Method to get Operating System Version Major.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersionMajor
    *   @return {number} The OS version Major.
    */
  
    $.fn.deviceDetector.getOsVersionMajor = function() {
        return getOsVersion().categories.major;
    };
  
    /**
    *   Method to get Operating System Version Minor.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersionMinor
    *   @return {number} The OS version Minor.
    */
  
    $.fn.deviceDetector.getOsVersionMinor = function() {
        return getOsVersion().categories.minor;
    };
  
    /**
    *   Method to get Operating System Version Bugfix.
    *   @function external:"jQuery.fn.deviceDetector".getOsVersionBugfix
    *   @return {number} The OS version Bugfix.
    */
  
    $.fn.deviceDetector.getOsVersionBugfix = function() {
        return getOsVersion().categories.bugfix;
    };
  
    /**
    *   Method to get Operating System Name.
    *   @function external:"jQuery.fn.deviceDetector".getOsName
    *   @return {string} The OS name.
    */
  
    $.fn.deviceDetector.getOsName = function() {
        return getOsName();
    };
  
    /**
    *   Method to get Operating System Id (Short Name).
    *   @function external:"jQuery.fn.deviceDetector".getOsId
    *   @return {string} The OS id.
    */
  
    $.fn.deviceDetector.getOsId = function() {
        return getOsName(true);
    };
  
    /**
    *   Method to detect Browser and Device support.
    *   @function external:"jQuery.fn.deviceDetector".isSupported
    *   @return {boolean} The match status.
    */
  
    $.fn.deviceDetector.isSupported = function() {
        return isSupported();
    };
  
    /**
    *   Method to get all available device and browser informations.
    *   @function external:"jQuery.fn.deviceDetector".getInfo
    *   @return {object} The device and browser infromation.
    */
  
    $.fn.deviceDetector.getInfo = function() {
        return {
            'browserVersion'        :   this.getBrowserVersion(),
            'browserName'           :   this.getBrowserName(),
            'browserId'             :   this.getBrowserId(),
            'osVersion'             :   this.getOsVersion(),
            'osVersionString'       :   this.getOsVersionString(),
            'osVersionCategories'   :   this.getOsVersionCategories(),
            'osVersionMajor'        :   this.getOsVersionMajor(),
            'osVersionMinor'        :   this.getOsVersionMinor(),
            'osVersionBugfix'       :   this.getOsVersionBugfix(),
            'osName'                :   this.getOsName(),
            'osId'                  :   this.getOsId(),
            'supported'             :   isSupported(),
            'mobile'                :   this.isMobile(),
            'desktop'               :   this.isDesktop(),
            'safari'                :   this.isSafari(),
            'iphone'                :   this.isIphone(),
            'ipad'                  :   this.isIpad(),
            'ios'                   :   this.isIos(),
            'macos'                 :   this.isMacos(),
            'chrome'                :   this.isChrome(),
            'android'               :   this.isAndroid(),
            'firefox'               :   this.isFirefox(),
            'ie'                    :   this.isIe(),
            'msie'                  :   this.isMsie(),
            'edge'                  :   this.isEdge(),
            'ieMobile'              :   this.isIeMobile(),
            'windowsPhone'          :   this.isWindowsPhone(),
            'windows'               :   this.isWindows(),
            'opera'                 :   this.isOpera(),
            'operaMini'             :   this.isOperaMini(),
            'blackberry'            :   this.isBlackberry(),
            'linux'                 :   this.isLinux(),
            'bsd'                   :   this.isBsd(),
        };
    };
  
    /**
    *   Method to remove empty Values from a Array.
    *   @function removeEmptyValuesFromArray
    *   @param {array} arr The Array to remove empty Values.
    *   @return {array} The new Array without empty Values.
    */
    function removeEmptyValuesFromArray(arr) {
        return $.map( arr, function(value) {
            return value === '' ? null : value;
        });
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__removeEmptyValuesFromArray = removeEmptyValuesFromArray;
    /* end: test-code */
  
    /**
    *   Method to detect Characters matching.
    *   @function isMatching
    *   @param {array} arr The Characters to match.
    *   @return {boolean} The match status.
    */
    function isMatching(arr) {
        let status  =   false; // eslint-disable-line no-var
        let newArr  =   removeEmptyValuesFromArray(arr); // eslint-disable-line no-var
        $.each( newArr, function( index, value ) {
            value   =   ('' + value).toLowerCase();
            status  =
                browserAgentString.indexOf(value) > -1 ||
                browserVersionString.indexOf(value) > -1;
       
                if (status === true) return false;
         });
        
         return status;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__isMatching = isMatching;
    /* end: test-code */
  
    /**
    *   Method to detect Device Type.
    *   @function isDeviceType
    *   @param {array|string} includes The Device to match.
    *   @param {array|string} excludes The Device should not match.
    *   @return {boolean} The Device Type match status.
    */
    function isDeviceType(includes, excludes) {
        try {
            let hasIncludes = false; // eslint-disable-line no-var
            let hasExcludes = false; // eslint-disable-line no-var
  
            if (!$.isArray(includes)) includes = $.makeArray(includes);
  
            if (!$.isArray(excludes)) excludes = $.makeArray(excludes);
  
            hasExcludes = isMatching(excludes);
  
            if (hasExcludes === false) hasIncludes = isMatching(includes);
  
            return hasIncludes;
        }catch (error) {
            console.info( // eslint-disable-line no-console
                'deviceDetector: No match String || Array given in isDeviceType()',
                error
            );
        }
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__isDeviceType = isDeviceType;
    /* end: test-code */
  
    /**
     * Method to get the Browser Version.
     * @function getBrowserVersion
     * @return {number} The browser Version. Default is 0.
     */
    function getBrowserVersion() {
      var version = 0; // eslint-disable-line no-var
      var data = // eslint-disable-line no-var
        browserAgentString +
        browserVersionString;
      $.each(config.browsers.versions, function(key, value) {
        var index = data.indexOf(value.index); // eslint-disable-line no-var
        if (index > -1) {
          version = parseFloat(
            data.substring(index + value.map).split('.')[0]
          );
          return false;
        }
      });
      return version;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__getBrowserVersion = getBrowserVersion;
    /* end: test-code */
  
    /**
     * Method to get the Browser Name.
     * @function getBrowserName
     * @param {boolean} returnId The Method should return an Id
     * (browser short name) instead of the Name (browser long name)
     * @return {string} The browser Name. Default ist unknown.
     */
    function getBrowserName(returnId) {
      var name = 'unknown'; // eslint-disable-line no-var
      $.each(config.browsers.names, function(key, value) {
        var isBrowser = isDeviceType( // eslint-disable-line no-var
          value.pattern.include,
          value.pattern.exclude
        );
        if (isBrowser) {
          (returnId === true) ? name = value.id : name = value.name;
          return false;
        }
      });
      return name;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__getBrowserName = getBrowserName;
    /* end: test-code */
  
    /**
     * Method to get the Operating System Version.
     * @function getOsVersion
     * @return {object} The OS Version . Default is 0.
     * {
     *  'string': '0',
     *  'categories': {
     *    'major': 0,
     *    'minor': 0,
     *    'bugfix': 0,
     *  }
     * }
     */
    function getOsVersion() {
      var version = { // eslint-disable-line no-var
        'string': '0.0.0',
        'categories': {
          'major': 0,
          'minor': 0,
          'bugfix': 0,
        },
      };
      var data = // eslint-disable-line no-var
        browserAgentString +
        browserVersionString;
      $.each(config.oss.versions, function(key, value) {
        var index = data.indexOf(value.index); // eslint-disable-line no-var
        if (index > -1) {
          version.string =
            data.substring(index + value.map).split(value.cut)[0]
            || '0.0.0';
          var choped = // eslint-disable-line no-var
            version.string.split(value.chop);
          version.categories.major = parseInt(choped[0]) || 0;
          version.categories.minor = parseInt(choped[1]) || 0;
          version.categories.bugfix = parseInt(choped[2]) || 0;
          return false;
        }
      });
      return version;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__getOsVersion = getOsVersion;
    /* end: test-code */
  
    /**
     * Method to get the Operating System Name.
     * @function getOsName
     * @param {boolean} returnId The Method should return an Id
     * (OS short name) instead of the Name (OS long name)
     * @return {string} The OS Name. Default ist unknown.
     */
    function getOsName(returnId) {
      var name = 'unknown'; // eslint-disable-line no-var
      $.each(config.oss.names, function(key, value) {
        var isOs = isDeviceType( // eslint-disable-line no-var
          value.pattern.include,
          value.pattern.exclude
        );
        if (isOs) {
          (returnId === true) ? name = value.id : name = value.name;
          return false;
        }
      });
      return name;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__getOsName = getOsName;
    /* end: test-code */
  
    /**
     * Method to detect supported Browser.
     * @function isSupported
     * @return {boolean} The supported Browser match status.
     */
    function isSupported() {
      var isSupported = false; // eslint-disable-line no-var
      $.each(config.supports, function(key, value) {
        if (
          getBrowserName(true) === value.id &&
          getBrowserVersion() >= parseFloat(value.version)
        ) isSupported = true;
      });
      return isSupported;
    }
  
    /* start: test-code */
    $.fn.deviceDetector.__isSupported = isSupported;
    /* end: test-code */
  
    // private variables
    var browser = navigator; // eslint-disable-line no-var
    var browserAgentString = // eslint-disable-line no-var
      ('' + browser.userAgent).toLowerCase();
    var browserVersionString = // eslint-disable-line no-var
      ('' + browser.appVersion).toLowerCase();
    browserAgentString = ('' + browserAgentString).toLowerCase();
    browserVersionString = browserAgentString || browserVersionString;
  
    // config
    //
    //  vendor
    //    Apple
    //    Google
    //    Mozilla
    //    Microsoft
    //    Opera
    //    Blackberry
    //    lbu (linux, bsd and unix)
    //  browsers
    //    names: browser nameings and patterns
    //    versions: index / matching patterns
    //  oss
    //    names: os nameings and patterns
    //    versions: index / matching patterns
    //  mobile
    //    mobile matching patterns
    //  supports
    //    supported browser / browser matrix
    var config = {}; // eslint-disable-line no-var
    $.extend(
      config,
      {
        'vendors': {
          'apple': {
            'safari': {
              'pattern': {
                'include': 'safari',
                'exclude': ['chrome', 'iemobile', 'opr/', 'opera'],
              },
            },
            'iphone': {
              'pattern': {
                'include': 'iphone',
                'exclude': 'iemobile',
              },
            },
            'ipad': {
              'pattern': {
                'include': 'ipad',
                'exclude': 'iemobile',
              },
            },
            'ios': {
              'pattern': {
                'include': ['ipad', 'iphone', 'ipod'],
                'exclude': 'iemobile',
              },
            },
            'macos': {
              'pattern': {
                'include': 'mac os',
                'exclude': ['iphone', 'ipad', 'ipod'],
              },
            },
          },
          'google': {
            'chrome': {
              'pattern': {
                'include': 'chrome',
                'exclude': ['edge', 'msie', 'firefox', 'opr/', 'opera'],
              },
            },
            'android': {
              'pattern': {
                'include': 'android',
                'exclude': 'windows phone',
              },
            },
          },
          'mozilla': {
            'firefox': {
              'pattern': {
                'include': 'firefox',
                'exclude': '',
              },
            },
          },
          'microsoft': {
            'msie': {
              'pattern': {
                'include': ['trident', 'msie'],
                'exclude': 'iemobile',
              },
            },
            'edge': {
              'pattern': {
                'include': 'edge',
                'exclude': 'iemobile',
              },
            },
            'ie': {
              'pattern': {
                'include': [
                  'trident',
                  'msie',
                  'edge',
                ],
                'exclude': 'iemobile',
              },
            },
            'ieMobile': {
              'pattern': {
                'include': 'iemobile',
                'exclude': '',
              },
            },
            'windows': {
              'pattern': {
                'include': 'windows nt',
                'exclude': '',
              },
            },
            'windowsMobile': {
              'pattern': {
                'include': 'windows phone',
                'exclude': '',
              },
            },
            'windowsXp': {
              'pattern': {
                'include': 'windows nt 5',
                'exclude': '',
              },
            },
            'windowsVista': {
              'pattern': {
                'include': 'windows nt 6.0',
                'exclude': '',
              },
            },
            'windows7': {
              'pattern': {
                'include': 'windows nt 6.1',
                'exclude': '',
              },
            },
            'windows8': {
              'pattern': {
                'include': 'windows nt 6.2',
                'exclude': '',
              },
            },
            'windows81': {
              'pattern': {
                'include': 'windows nt 6.3',
                'exclude': '',
              },
            },
            'windows10': {
              'pattern': {
                'include': 'windows nt 10.0',
                'exclude': '',
              },
            },
            'windowsPhone': {
              'pattern': {
                'include': 'windows phone',
                'exclude': '',
              },
            },
          },
          'opera': {
            'opera': {
              'pattern': {
                'include': ['opera', 'opr', 'presto'],
                'exclude': 'opera mini',
              },
            },
            'operaMini': {
              'pattern': {
                'include': 'opera mini',
                'exclude': '',
              },
            },
          },
          'blackberry': {
            'blackberry': {
              'pattern': {
                'include': 'blackberry',
                'exclude': '',
              },
            },
          },
          'lbu': {
            'linux': {
              'pattern': {
                'include': 'linux',
                'exclude': '',
              },
            },
            'bsd': {
              'pattern': {
                'include': ['bsd', 'unix', 'sunos'],
                'exclude': '',
              },
            },
            'unixlike': {
              'pattern': {
                'include': ['linux', 'bsd', 'unix', 'sunos', 'X11'],
                'exclude': ['mobile', 'tablet', 'phone', 'droid'],
              },
            },
          },
        },
      }
    );
    $.extend(
      config,
      {
        'browsers': {
          'names': {
            'edge': {
              'id': 'edge',
              'name': 'Microsoft Edge',
              'pattern': config.vendors.microsoft.edge.pattern,
            },
            'ie': {
              'id': 'msie',
              'name': 'Microsoft Internet Explorer',
              'pattern': config.vendors.microsoft.ie.pattern,
            },
            'ieMobile': {
              'id': 'ieMobile',
              'name': 'Microsoft Internet Explorer Mobile',
              'pattern': config.vendors.microsoft.ieMobile.pattern,
            },
            'chrome': {
              'id': 'chrome',
              'name': 'Google Chrome',
              'pattern': config.vendors.google.chrome.pattern,
            },
            'safari': {
              'id': 'safari',
              'name': 'Apple Safari',
              'pattern': config.vendors.apple.safari.pattern,
            },
            'firefox': {
              'id': 'firefox',
              'name': 'Mozilla Firefox',
              'pattern': config.vendors.mozilla.firefox.pattern,
            },
            'opera': {
              'id': 'opera',
              'name': 'Opera',
              'pattern': config.vendors.opera.opera.pattern,
            },
            'operaMini': {
              'id': 'operaMini',
              'name': 'Opera Mini',
              'pattern': config.vendors.opera.operaMini.pattern,
            },
            'blackberry': {
              'id': 'blackberry',
              'name': 'BlackBerry',
              'pattern': config.vendors.blackberry.blackberry.pattern,
            },
          },
          'versions': {
            'default': {
              'index': 'rv:',
              'map': 3,
            },
            'edge': {
              'index': 'edge/',
              'map': 5,
            },
            'msie': {
              'index': 'msie ',
              'map': 5,
            },
            'ieMobile': {
              'index': 'iemobile/',
              'map': 9,
            },
            'chrome': {
              'index': 'chrome/',
              'map': 7,
            },
            'firefox': {
              'index': 'firefox/',
              'map': 8,
            },
            'opr': {
              'index': 'opr/',
              'map': 4,
            },
            'operaMini': {
              'index': 'opera/',
              'map': 6,
            },
            'opera': {
              'index': 'opera ',
              'map': 5,
            },
            'safari': {
              'index': 'version/',
              'map': 8,
            },
          },
        },
      }
    );
    $.extend(
      config,
      {
        'oss': {
          'names': {
            'windowsPhone': {
              'id': 'windowsPhone',
              'name': 'Microsoft Windows Phone',
              'pattern': config.vendors.microsoft.windowsPhone.pattern,
            },
            'windowsXp': {
              'id': 'windowsXp',
              'name': 'Microsoft Windows XP',
              'pattern': config.vendors.microsoft.windowsXp.pattern,
            },
            'windowsVista': {
              'id': 'windowsVista',
              'name': 'Microsoft Windows Vista',
              'pattern': config.vendors.microsoft.windowsVista.pattern,
            },
            'windows7': {
              'id': 'windows7',
              'name': 'Microsoft Windows 7',
              'pattern': config.vendors.microsoft.windows7.pattern,
            },
            'window8': {
              'id': 'windows8',
              'name': 'Microsoft Windows 8',
              'pattern': config.vendors.microsoft.windows8.pattern,
            },
            'window81': {
              'id': 'windows81',
              'name': 'Microsoft Windows 8.1',
              'pattern': config.vendors.microsoft.windows81.pattern,
            },
            'windows10': {
              'id': 'windows10',
              'name': 'Microsoft Windows 10',
              'pattern': config.vendors.microsoft.windows10.pattern,
            },
            'macos': {
              'id': 'macos',
              'name': 'Apple Mac OS X',
              'pattern': config.vendors.apple.macos.pattern,
            },
            'ios': {
              'id': 'ios',
              'name': 'Apple iOS',
              'pattern': config.vendors.apple.ios.pattern,
            },
            'android': {
              'id': 'android',
              'name': 'Android Linux',
              'pattern': config.vendors.google.android.pattern,
            },
            'linux': {
              'id': 'linux',
              'name': 'GNU/Linux OS',
              'pattern': config.vendors.lbu.linux.pattern,
            },
            'bsd': {
              'id': 'bsd',
              'name': 'BSD OS',
              'pattern': config.vendors.lbu.bsd.pattern,
            },
            'blackberry': {
              'id': 'blackberry',
              'name': 'BlackBerry OS',
              'pattern': config.vendors.blackberry.blackberry.pattern,
            },
          },
          'versions': {
            'windowsPhone': {
              'index': config.vendors.microsoft.windowsPhone.pattern.include,
              'map': 14,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windowsXp': {
              'index': config.vendors.microsoft.windowsXp.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windowsVista': {
              'index': config.vendors.microsoft.windowsVista.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windows7': {
              'index': config.vendors.microsoft.windows7.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windows8': {
              'index': config.vendors.microsoft.windows8.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windows81': {
              'index': config.vendors.microsoft.windows81.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'windows10': {
              'index': config.vendors.microsoft.windows10.pattern.include,
              'map': 11,
              'cut': /;|\)/,
              'chop': '.',
            },
            'android': {
              'index': config.vendors.google.android.pattern.include,
              'map': 8,
              'cut': /;|\)/,
              'chop': '.',
            },
            'ios': {
              'index': 'cpu os ',
              'map': 7,
              'cut': / |\)/,
              'chop': '_',
            },
            'iphone': {
              'index': 'iphone os ',
              'map': 10,
              'cut': / |\)/,
              'chop': '_',
            },
            'ipad': {
              'index': 'ipad os ',
              'map': 8,
              'cut': / |\)/,
              'chop': '_',
            },
            'macos': {
              'index': 'mac os x',
              'map': 9,
              'cut': / |\)|;/,
              'chop': /_|\./,
            },
            'bsd': {
              'index': config.vendors.lbu.bsd.pattern.include,
              'map': 5,
              'cut': / |\)/,
              'chop': '.',
            },
            'linux': {
              'index': config.vendors.lbu.linux.pattern.include,
              'map': 5,
              'cut': /;| |\)/,
              'chop': '.',
            },
            'blackberry': {
              'index': config.vendors.blackberry.blackberry.pattern.include,
              'map': 10,
              'cut': /;|\)/,
              'chop': '.',
            },
          },
        },
      }
    );
    $.extend(
      config,
      {
        'mobile': {
          'pattern': {
            'include': ['mobile', 'tablet', 'phone', 'droid'],
            'exclude': '',
          },
        },
      }
    );
    $.extend(
      config,
      {
        'supports': {
          'msie': {'id': 'msie', 'version': '11'},
          'edge': {'id': 'edge', 'version': '12'},
          'chrome': {'id': 'chrome', 'version': '66'},
          'firefox': {'id': 'firefox', 'version': '60'},
          'safari': {'id': 'safari', 'version': '11'},
        },
      }
    );
}(jQuery));

