import { AbstractService } from "./AbstractService";

class NoRgpdService extends AbstractService{
    constructor(state = 1){
        super(state);
        this.setId('no-rgpd-service');
    }

    on(){
        this.state  =   1;
    }
    off(){
        //this.state  =   0;
    }
}

export {NoRgpdService}