import "jquery-ui/ui/widgets/autocomplete";

$(function(){
    function autocomplete(){
        let input       =   $(this);
        
        if($.isUndefined(input.data('b-search'))){
            let form        =   input.parents('form:first');
            let $form       =   form.data($.getInjector('forms')+'Motor');
            $form.init(form);
            let component   =   form.parent();
            let submit      =   form.find('[type="button"]');
            let search      =   null;
            let timeout     =   null;
    
            input.data('b-search', true);
    
            submit.on('click', function(e){
                $.stop(e);
                let form    =   $(e.currentTarget).parents('form:first');
                let input   =   form.find('input.b-autocomplete');
                input.trigger('focus').trigger('keydown');
            });
            
            input.on('keypress', function(e){
                if($.isEnter(e)){
                    $.stop(e);
    
                    input.trigger('keydown');
                }
            });
        
            let parameters  =   {
                minLength   :   1,
                delay       :   1,
                scrollHeight:   220,
                select      :   select,
                focus       :   focus,
                source      :   source,
                close       :   close,
                open        :   open,
                input       :   input,
                form        :   form,
                component   :   component,
                submit      :   submit,
                id          :   $('#'+form.attr('id')+'_event_id').val(),
            };

            parameters.id   =   parameters.id.replaceAll('.', '-');
            input.data('b-search-parameters', parameters);
    
            function select(event, ui){
                let parameters      =   $(event.target).data('b-search-parameters');
                parameters.event    =   event,
                parameters.ui       =   ui,
                parameters.target   =   $(event.target);
    
                $.isDefined(parameters.id) ?   $.trigger(parameters.id+'.select', parameters)  :   $.trigger('b-search.select', parameters);
            }
            function focus(event, ui){
                let parameters  =   $(event.target).data('b-search-parameters');
                parameters.event=   event,
                parameters.ui   =   ui,
    
                $.isDefined(parameters.id) ?   $.trigger(parameters.id+'.focus', parameters)  :   $.trigger('b-search.focus', parameters);
            }
            function source(req, add){
                if(req.term.length >= 1){
                    let element =   this.element;
                    let form    =   element.parents('form');
                    let $form   =   form.data($.getInjector('forms')+'Motor');
                    search      =   req;
    
                    timeout !=  null    ?   clearTimeout(timeout)   :   null;
    
                    $form.init(form);
                    $form.abort();
    
                    $form   .set('loading', false) 
                            .set('doneAjax', function(response){
                                if(response.state){
                                    if($.isUndefined(response.results) || response.results == null || response.results.length == 0){
                                        response.results    =   [{label : 'empty', value : 'empty' }];
                                    }

                                    add(response.results, req);

                                    return 'continue';
                                }
                            })
                    
                    timeout     =   window.setTimeout(function(){ 
                        $form.send();
                    }, 1500);
                }
            }
            function close(event, ui){
                $.trigger('b-search.close', {event : event, ui : ui});
            }
            function open(event, ui){
                $.trigger('b-search.open', {event : event, ui : ui});
            }
    
            let autocomplete    =   input.autocomplete(parameters);    
            let instance        =   autocomplete.autocomplete('instance');

            instance._renderItem=   function(ul, item){
                return  $.trigger(parameters.id+'.render', {ul : ul, item : item, search : search});
            };
        }
    }

    $('.b-autocomplete').each(autocomplete);

    $(document).on('b-search.open', function(e){
        let event   =   e.parameters.event;
        let target  =   $(event.target);
        let widget  =   target.autocomplete('widget');
        let zindex  =   1050;

        if(target.parent('.b-window').length > 0){
            let $window =   target.parent('.b-window').data($.getInjector('window'));
            $window.inti(target.parent('.b-window'));

            zindex  =   $window.get('zindex');
        }

        $(widget).css('z-index', zindex);
    });

    $(document).on($.getKeyEvents('window')+'end', function(evt){
        let $window =   evt[$.getInjector('window')];
        let $this   =   $window;

        /**
        *   AUTOCOMPLETE SEARCH 
        */
        $this.getDom('body').find('.b-autocomplete').each(autocomplete);
    });
});