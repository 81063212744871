class AbstractFx {
    constructor(parameters){
        this.CURRENT_KEY=   null;
        this.ELEMENT    =   null;
        this.ELEMENTS   =   null;
        this.PARAMETERS =   null;
        this.CLASSNAME  =   null;
        this.SERVICE    =   null;
        this.APPLICATION=   null;
        this.FX         =   {};

        this.setPersonalParameters(parameters);
    }

    /**
     *   MANAGERS
     */
    setApplication(application){
        if($.isDefined(application)){
            this.APPLICATION    =   application;
        }
        return  this;
    }
    getApplication(){
        if($.isNull(this.APPLICATION)){
            this.setApplication($.getApplicationConfig());
        }
        return  this.APPLICATION;
    }
    setApplicationConfig(applicationConfig){
        return  this.setApplication(applicationConfig);
    }
    getApplicationConfig(){
        return  this.getApplication();
    }

    setService(service){
        if($.isDefined(service)){
            this.SERVICE    =   service;
        }
        return this;
    }
    getService(){
        if($.isNull(this.SERVICE)){
            this.setService($.getServiceManager());
        }
        return this.SERVICE;
    }
    setServiceManager(serviceManager){
        return  this.setService(serviceManager);
    }
    getServiceManager(){
        return  this.getService();
    }

    setFx(name, fx){
        if($.isDefined(name) && $.isDefined(fx)){
            this.FX[name]    =   fx;
        }
        return this;
    }
    getFx(name){
        return  $.isDefined(this.FX[name])  ?   this.FX[name] :   null;
    }


    /**
     *   GLOBAL
     */
    setClassName(className){
        if($.isDefined(className)){
            this.CLASSNAME  =   className;
        }

        return this;
    }
    getClassName(){
        return  this.CLASSNAME;
    }

    setElements(elements){
        if($.isDefined(elements)){
            this.ELEMENTS   =   elements;
        }
        return this;
    }
    getElements(){
        return  this.ELEMENTS;
    }
    setElement(element){
        if($.isDefined(element)){
            this.ELEMENT    =   element;
        }
        return this;
    }
    getElement(){
        return  this.ELEMENT;
    }

    getExtractKey(element){
        element =   element || this.getElement();
        let key =   $.md5();

        if($.isDefined(element) && $.isDefined(element.data($.getInjector(this.getClassName())))){
            key =   element.data($.getInjector(this.getClassName()));
        }

        return  key;
    }
    setCurrentKey(currentKey){
        if($.isDefined(currentKey)){
            this.CURRENT_KEY    =   currentKey;
        }
        return  this;
    }
    getCurrentKey(create = false){
        if($.isNull(this.CURRENT_KEY) || create){
            this.setCurrentKey(this.getExtractKey());
        }
        return  this.CURRENT_KEY;
    }

    setParameters(parameters){
        if($.isDefined(parameters)){
            this.PARAMETERS =   parameters;
        }
        return  this;
    }
    getParameters(){
        if($.isNull(this.PARAMETERS)){
            this.initParameters();
        }
        return this.PARAMETERS;
    }
    setParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getParameters();
            parameters[key] =   value;
            this.setParameters(parameters);
        }
        return this;
    }
    getParameter(key){
        let parameters  =   this.getParameters();
        return  $.isDefined(parameters[key])   ?   parameters[key]    :   null;
    }
    initParameters(){
        this.setParameters({
            DEFAULT :   null,
            PERSONAL:   null,
            AUTO    :   null,
            INITIAL :   null,
            EXTERNAL:   null,
        });
        return  this;
    }

    /**
     *   PERSONAL PARAMETERS
     */
    setPersonalParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter('PERSONAL', parameters);
        }
        return this;
    }
    getPersonalParameters(){
        if($.isNull(this.getParameter('PERSONAL'))){
            this.initPersonalParameters();
        }
        return  this.getParameter('PERSONAL');
    }
    setPersonalParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getPersonalParameters();
            parameters[key] =   value;
            this.setPersonalParameters(parameters);
        }
        return this;
    }
    getPersonalParameter(key){
        return  $.isDefined(this.getPersonalParameters()[key])  ?   this.getPersonalParameters()[key]   :   null;
    }
    setPersonal(key, value){
        return  this.setPersonalParameter(key, value);
    }
    getPersonal(key){
        return  this.getPersonalParameter(key);
    }
    initPersonalParameters(){
        return  this.setPersonalParameters({});
    }

    setPersonalAttributes(attributes){
        if($.isDefined(attributes)){
            this.setPersonal('attributes', attributes);
        }
        return this;
    }
    getPersonalAttributes(){
        if($.isNull(this.getPersonal('attributes'))){
            this.setPersonalAttributes({});
        }
        return this.getPersonal('attributes');
    }
    setPersonalAttribute(key, attribute){
        if($.isDefined(key) && $.isDefined(attribute)){
            let attributes  =   this.getPersonalAttributes();
            attributes[key] =   attribute;
            this.setPersonalAttributes(attributes);
        }
        return this;
    }
    getPersonalAttribute(key){
        return  $.isDefined(this.getPersonalAttributes()[key])  ?   this.getPersonalAttributes()[key]   :   null;
    }

    /**
     *   DEFAULT PARAMETERS
     */
    setDefaultParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter('DEFAULT', parameters);
        }
        return this;
    }
    getDefaultParameters(){
        if($.isNull(this.getParameter('DEFAULT'))){
            this.initDefaultParameters();
        }
        return this.getParameter('DEFAULT');
    }
    setDefaultParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getDefaultParameters();
            parameters[key] =   value;
            this.setDefaultParameters(parameters);
        }
        return this;
    }
    getDefaultParameter(key){
        return  $.isDefined(this.getDefaultParameters()[key])   ?   this.getDefaultParameters()[key]    :   null;
    }
    setDefault(key, value){
        return  this.setDefaultParameter(key, value);
    }
    getDefault(key){
        return  this.getDefaultParameter(key);
    }
    initDefaultParameters(){
        return  this.setDefaultParameters({});
    }

    setDefaultAttributes(attributes){
        if($.isDefined(attributes)){
            this.setDefault('attributes', attributes);
        }
        return this;
    }
    getDefaultAttributes(){
        if($.isNull(this.getDefault('attributes'))) {
            this.initDefaultAttributes();
        }

        return  this.getDefault('attributes');
    }
    setDefaultAttribute(key, attribute){
        if($.isDefined(key) && $.isDefined(attribute)){
            let attributes  =   this.getDefaultAttributes();
            attributes[key] =   attribute;
            this.setDefaultAttributes(attributes);
        }
        return  this;
    }
    getDefaultAttribute(key){
        return  $.isDefined(this.getDefaultAttributes()[key])  ?   this.getDefaultAttributes()[key]   :   null;
    }
    initDefaultAttributes(){
        return  this.setDefaultAttributes({});
    }

    /**
     *   AUTO PARAMETERS
     */
    setAutoParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter('AUTO', parameters);
        }
        return this;
    }
    getAutoParameters(){
        if($.isNull(this.getParameter('AUTO'))){
            this.initAutoParameters();
        }
        return this.getParameter('AUTO');
    }
    setAutoParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getAutoParameters();
            parameters[key] =   value;
            this.setAutoParameters(parameters);
        }
        return this;
    }
    getAutoParameter(key){
        return  $.isDefined(this.getAutoParameters()[key])  ?   this.getAutoParameters()[key]   :   null;
    }
    setCurrentAutoParameters(parameters){
        if($.isDefined(parameters)){
            this.setAutoParameter(this.getCurrentKey(), parameters);
        }
        return this;
    }
    getCurrentAutoParameters(){
        if($.isNull(this.getAutoParameter(this.getCurrentKey()))){
            this.initCurrentAutoParameters();
        }
        return  this.getAutoParameter(this.getCurrentKey());
    }
    setCurrentAutoParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getCurrentAutoParameters();
            parameters[key] =   value;
            this.setCurrentAutoParameters(parameters);
        }

        return this;
    }
    getCurrentAutoParameter(key){
        return  $.isDefined(this.getCurrentAutoParameters()[key])   ?   this.getCurrentAutoParameters()[key]    :   null;
    }
    setAuto(key, value){
        return  this.setCurrentAutoParameter(key, value);
    }
    getAuto(key){
        return  this.getCurrentAutoParameter(key);
    }
    initAutoParameters(){
        return  this.setAutoParameters({});
    }
    initCurrentAutoParameters(){
        return  this.get('auto')    ?   this.setCurrentAutoParameters(this.getExtractParameters())  :   this.setCurrentAutoParameters({});
    }
    getExtractParameters(attributes){
        let extract =   {};
        let obj		=	attributes	||	this.get('attributes');

        for(let key in obj) {
            if ($.isPlainObject(obj[key])) {
                extract[key] = this.getExtractParameters(obj[key]);
            }
            else {
                extract[key] = this.getElement().attr(obj[key]);
            }
        }

        return extract;
    }

    /**
     *   CURRENT PARAMETERS
     */
    setCurrentParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter(this.getCurrentKey(), parameters);
        }
        return this;
    }
    getCurrentParameters(){
        if($.isUndefined(this.getParameter(this.getCurrentKey()))){
            this.initCurrentParameters();
        }
        return  this.getParameter(this.getCurrentKey());
    }
    setCurrentParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getCurrentParameters();
            parameters[key] =   value;
            this.setCurrentParameters(parameters);
        }
        return this;
    }
    getCurrentParameter(key){
        return  $.isDefined(this.getCurrentParameters()[key])   ?   this.getCurrentParameters()[key]    :   null;
    }
    initCurrentParameters(){
        let parameters          =   $.extend(true, true, {}, this.getDefaultParameters(), this.getPersonalParameters());
        parameters.attributes   =   $.extend(true, true, {}, this.getDefaultAttributes(), this.getPersonalAttributes());
        parameters.element      =   this.getElement();

        parameters.auto         =   this.getElement().attr(parameters.attributes.auto)  ||  parameters.auto;

        this.setCurrentParameters(parameters);
        if($.isTrue(this.get('auto'))){
            parameters  =   $.extend(true, true, parameters, this.getCurrentAutoParameters());
            this.setCurrentParameters(parameters);
        }

        if($.isTrue(this.get('fire')) && $.isNull(this.get('events'))){
            this.set('events', 'click')
                .set('one', true);
        }

        this.initializeKeys();

        this.setCurrentInitialParameters($.extend(true, true, {}, parameters));
        return  this;
    }
    setCurrent(key, value){
        return  this.setCurrentParameter(key, value);
    }
    getCurrent(key){
        return  this.getCurrentParameter(key);
    }
    set(key, value){
        return  this.setCurrent(key, value);
    }
    get(key){
        return  this.getCurrent(key);
    }

    /**
    *   INITAL PARAMETERS
    */
    setInitialParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter('INITIAL', parameters);
        }
        return this;
    }
    getInitialParameters(){
        if($.isNull(this.getParameter('INITIAL'))){
            this.initInitialParameters();
        }
        return  this.getParameter('INITIAL');
    }
    setInitialParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getInitialParameters();
            parameters[key] =   value;
            this.setInitialParameters(parameters);
        }
        return this;
    }
    getInitialParameter(key){
        return  $.isDefined(this.getInitialParameters()[key])   ?   this.getInitialParameters()[key]    :   null
    }
    setCurrentInitialParameters(parameters){
        return  this.setInitialParameter(this.getCurrentKey(), parameters);
    }
    getCurrentInitialParameters(){
        return  this.getInitialParameter(this.getCurrentKey());
    }
    getCurrentInitialParameter(key){
        return  $.isDefined(this.getCurrentInitialParameters()[key])    ?   this.getCurrentInitialParameters()[key] : null;
     }
    initInitialParameters(){
        return  this.setInitialParameters({})
    }

    /**
     *   EXTERNAL PARAMETERS
     */
    setExternalParameters(parameters){
        if($.isDefined(parameters)){
            this.setParameter('EXTERNAL', parameters);
        }
        return this;
    }
    getExternalParameters(){
        if($.isNull(this.getParameter('EXTERNAL'))){
            this.initExternalParameters();
        }
        return  this.getParameter('EXTERNAL');
    }
    setExternalParameter(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            let parameters  =   this.getExternalParameters();
            parameters[key] =   value;
            this.setExternalParameters(parameters);
        }
        return this;
    }
    getExternalParameter(key){
        return  $.isDefined(this.getExternalParameters()[key])   ?   this.getExternalParameters()[key]    :   null
    }
    setCurrentExternalParameters(parameters){
        return this.setExternalParameter(this.getCurrentKey(), parameters);
    }
    getCurrentExternalParameters(){
        return  this.getExternalParameter(this.getCurrentKey());
    }
    getCurrentExternalParameter(key){
        return  $.isDefined(this.getCurrentExternalParameters()[key])    ?   this.getCurrentExternalParameters()[key] : null;
    }
    initExternalParameters(){
        return  this.setExternalParameters({})
    }

    /**
    *   SET / GET CURRENT
    */
    setKeys(keys){
        if($.isDefined(keys)){
            this.set('keys', keys);
        }
        return  this;
    }
    getKeys(){
        if($.isNull(this.get('keys'))){
            this.initializeKeys();
        }
        return  this.get('keys');
    }
    setKey(id, key){
        if($.isDefined(id) && $.isDefined(key)){
            let keys    =   this.getKeys();
            keys[id]    =   key;
            this.setKeys(keys);
        }
        return  this;
    }
    getKey(name){
        return  $.isDefined(this.getKeys()[name])   ?   this.getKeys()[name]    :   null;
    }
    initializeKeys(){
        return  this.setKeys({
                    injector    :   $.getInjector(this.getClassName()),
                    events      :   $.getKeyEvents(this.getClassName()),
                    prefix      :   $.getKeyPrefix(this.getClassName()),
                    selector    :   $.getKeySelector(this.getClassName()),
                    parameters  :   $.getInjector(this.getClassName())+'.parameters',
                    lock        :   $.getKeyPrefix(this.getClassName())+'lock',
                    id          :   this.getCurrentKey(),
                    name        :   this.getClassName(),
                    childs      :   [],
                });
    }

    setSelector(selector){
        if($.isDefined(selector)){
            this.set('selector', selector);
        }
        return  this;
    }
    getSelector(){
        return  this.get('selector');
    }

    setDoms(doms){
        if($.isDefined(doms)){
            this.set('doms', doms);
        }
        return this;
    }
    getDoms(){
        if($.isNull(this.get('doms'))){
            this.set('doms', {});
        }
        return this.get('doms');
    }
    setDom(name, dom){
        if($.isDefined(name) && $.isDefined(dom)){
            this.getDoms()[name]    =   dom;
        }
        return this;
    }
    getDom(name){
        return  $.isDefined(this.getDoms()[name])   ?   this.getDoms()[name]    :   null;
    }

    setTags(tags){
        if($.isDefined(tags)){
            this.set('tags', tags);
        }
        return this;
    }
    getTags(){
        if($.isNull(this.get('tags'))){
            this.setTags({});
        }
        return this.get('tags');
    }
    setTag(name, tag){
        if($.isDefined(name) && $.isDefined(tag)){
            this.getTags()[name]    =   tag;
        }
        return this;
    }
    getTag(name){
        return  $.isDefined(this.getTags()[name])   ?   this.getTags()[name]    :   null;
    }

    setData(key, data){
        if($.isDefined(key) && $.isDefined(data)){
            this.getData()[key]   =   data;
        }
        return  this;
    }
    getData(key){
        if($.isDefined(key)){
            return  $.isDefined(this.get('data')[key])  ?   this.get('data')[key]   :   null;
        }
        else{
            return  this.get('data');
        }
    }

    setValues(values){
        if($.isDefined(values)){
            this.get('data').values    =   values;
        }

        return  this;
    }
    getValues(){
        if($.isNull(this.get('data').values)){
            this.setValues({});
        }
        return  this.get('data').values;
    }
    setValue(key, value){
        if($.isDefined(key) && $.isDefined(value)){
            this.getValues()[key]   =   value;
        }
        return  this;
    }
    getValue(key){
        return  $.isDefined(this.getValues()[key])  ?   this.getValues()[key]   :   null;
    }

    setPlugins(plugins){
        if($.isDefined(plugins)){
            this.set('plugins', plugins);
        }
        return this;
    }
    getPlugins(){
        if($.isNull(this.get('plugins'))){
            this.setPlugins({});
        }
        return this.get('plugins');
    }
    setPlugin(name, plugin){
        if($.isDefined(name) && $.isDefined(plugin)){
            this.getPlugins()[name] =   plugin;
        }
        return this;
    }
    getPlugin(name){
        return  $.isDefined(this.getPlugins()[name]) ?   this.getPlugins()[name] :   null;
    }

    reset(){
        let doms    =   this.get('doms');
        let initial =   $.extend(true, true, {}, this.getInitialParameter(this.getCurrentKey()));
        this.setCurrentParameters(initial);
        this.set('doms', doms);


        return  this;
    }
    inject(element, motor = false){
        element =   element || this.getElement();

        if($.isDefined(element)){
            element.data(this.getKey('injector'), this.getKey('id'));

            if(motor){
                element.data(this.getKey('injector')+'Motor', this);
            }
        }

        return this;
    }
    initializeEvents(){
        let selector	    =	this.getSelector()+'['+this.getKey('prefix')+'one-'+this.get('events')+'!='+this.get('events')+']['+this.getKey('prefix')+'on-'+this.get('events')+'!='+this.get('events')+']';
        let $selector       =   selector;
        let proxy           =   $.proxy(this.begin, this);

        if($.isTrue(this.get('one'))){
            $('body').off(this.get('events'), $selector, proxy);

            this.getElement()
                .one(this.get('events'), proxy)
                .attr(this.getKey('prefix')+'one-'+this.get('events'), this.get('events'));

            if($.isTrue(this.get('live'))){
                $('body').on(this.get('events'), $selector, proxy);
            }
        }
        else if($.isTrue(this.get('live'))){
            $('body').off(this.get('events'), $selector, proxy);
            $('body').on(this.get('events'), $selector, proxy);
        }
        else{
            $('body').off(this.get('events'), $selector, proxy);
            this.getElement()
                .on(this.get('events'), proxy)
                .attr(this.getKey('prefix')+'on-'+this.get('events'), this.get('events'));

            if($.isTrue(this.get('live'))){
                $('body').on(this.get('events'), $selector, proxy);
            }
        }

        return this;
    }

    trigger(type){
        let event                       =   {};
        event.type                      =   this.getKey('events')+type;
        event[this.getKey('injector')]  =   this;
        event['key']                    =   this.getCurrentKey();
        let returne                     =   $.event.trigger(event);

        return  $.isNotNull(returne)    ?   this.setCurrentParameters(returne) :   this;
    }
}

export { AbstractFx }