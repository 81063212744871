import { AbstractValidatorFx } from "./AbstractValidatorFx";

class EqualValidatorFx extends AbstractValidatorFx{
    constructor(parameters){
        super(parameters);

        this.CLASSNAME  =   'equalvalidator';
        this.MESSAGES   =   {
            error	:	'<p class="b-validator-error">Erreur pour le champs %(label)s<br />La valeur doit correspondre avec le champs %(equal)s</p>',
            success	:	'',
        };
    }


    

    getError(){
        if(!this.get('state')){
            let label   =   $.escapeHtml(this.getLabel());
            let $label  =   $.escapeHtml(this.get('equalLabel'));
              
            this.setError(sprintf(this.MESSAGES.error, {
                label	:	label,
                equal   :   $label,
            }));

            return this.get('error');
        }
        else {
            return null;
        }
    }

    validate(){
        this.set('state', true);

        let element     =   this.getElement();
        let equal       =   this.getValidatorFx().get('equal');
        let $equal      =   equal.data($.getInjector('validators')+'Motor');
        $equal.init(equal);

        let equalValue  =   $equal.getValue();
        let equalLabel  =   $equal.getLabel();

        this.init(element);

        this.set('equalValue', equalValue);
        this.set('equalLabel', equalLabel);   

        if(equalValue != this.getValue()){
            this.set('state', false);
        }

        return this;
    }
}

export { EqualValidatorFx }